module.exports = {
  APP_ID: '1588e45333254509ae3d49d3d459c57c',
  APPIDENTIFIER: 'DB6ZoMKgEeuAIwFHLm23FA/qVgkam35ZxVINw',

  rongH5Url: 'https://rong.jingdata.com/m/#',
  skipLink: 'https://cloud.jingdata.com',
  behaviorLink: 'https://www.jingdata.com',

  sensor: {
    showLog: false,
    serverUrl: 'https://www.jingdata.com/global/sensors/sa?token=s5m07cbxnq349sxm74u1k76ghzyj9wvd&project=production',
  },
  sentry: true,
};
