<template>
  <el-dialog
    :visible="true"
    :close-on-click-modal="false"
    class="forget-dialog"
    append-to-body
    lock-scroll
    width="737px"
    destroy-on-close
    @close="onClose"
  >
    <template v-slot:title>
      <img
        class="header-logo"
        src="@/assets/img/logo-2@2x.png"
        alt=""
      >
    </template>
    <div class="content-wrapper">
      <reset-success
        v-if="step === 'success'"
        scene="dialog"
        class="reset-success"
      />
      <template v-else>
        <h1 class="card-title">
          重置密码
        </h1>
        <div class="form-wrapper">
          <reset-form
            scene="dialog"
            @reset-success="onResetSuccess"
          />
          <div class="bottom-row">
            <a @click="handleLogin('sms')">
              快速登录
            </a>
            <a @click="handleLogin('password')">
              账号登录
            </a>
          </div>
        </div>
      </template>
    </div>
  </el-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import ResetForm from '../ResetForm';
import ResetSuccess from '../ResetSuccess';

export default {
  components: {
    ResetForm,
    ResetSuccess,
  },
  data() {
    return {
      step: 'reset', // success
    };
  },

  computed: {

  },

  methods: {
    ...mapActions('login', [
      'showLoginDialog',
      'toggleForgetDialogVisible',
    ]),
    async onResetSuccess() {
      this.step = 'success';
    },

    handleLogin(type) {
      this.toggleForgetDialogVisible(false);
      this.$nextTick(() => {
        this.showLoginDialog({
          type,
        });
      });
    },

    onClose() {
      this.$store.dispatch('login/toggleForgetDialogVisible', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.forget-dialog {
  /deep/ .el-dialog {
    margin-top: calc((100vh - 524px) / 2) !important;
  }

  .header-logo {
    height: 24px;
    vertical-align: middle;
  }

  /deep/ .el-dialog__header {
    border-bottom: 0;

    .el-icon-close {
      font-size: 20px;
    }
  }

  /deep/ .el-dialog__body {
    padding: 0;
    height: calc(524px - 48px);
    max-height: calc(524px - 48px);
  }

  .content-wrapper {
    display: flex;
    margin-top: 12px;
    flex-direction: column;

    .card-title {
      font-size: 18px;
      color: #333b44;
      text-align: center;
      line-height: 26px;
      font-weight: bold;
    }

    .form-wrapper {
      width: 300px;
      margin-left: auto;
      margin-right: auto;

      /deep/ .el-form {
        width: auto;
      }
    }

    .bottom-row {
      margin-top: 24px;
      font-size: 14px;
      text-align: center;

      & a + a {
        margin-left: 40px;
      }
    }
  }

  .reset-success {
    width: 100%;
    padding-top: 0;
    margin-top: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
</style>
