import axios from 'axios';
import qs from 'qs';

/**
 * 当请求格式为application/x-www-form-urlencoded时需要将请求体格式化为URLSearchParams
 * @see https://github.com/axios/axios#using-applicationx-www-form-urlencoded-format
 */
const transformRequestParams = (data) => {
  if (!(data instanceof FormData)) {
    return qs.stringify(data);
  }
  return data;
};

// `paramsSerializer` is an optional function in charge of serializing `params`
// (e.g. https://www.npmjs.com/package/qs, http://api.jquery.com/jquery.param/)
const paramsSerializer = (params) => qs.stringify(params);

/**
 * 这里接口格式比较乱，不走通用请求逻辑
 */
const request = axios.create({
  baseURL: '/api/rong',
  timeout: 30000,
  transformRequest: [
    transformRequestParams,
  ],
  paramsSerializer,
});

export function getUserInfo() {
  return request({
    url: '/api/user/baseinfo',
    method: 'get',
  });
}

/**
 * 登陆+注册（兼第三方登陆绑定到已有账号） 此接口可需要极验验证码，当username尝试登陆，错误次数大于5次/天时，当天都需要极验验证。此时接口会返回：
{
"code":4032, "type":1, "url":"/captcha/v2"
}
表示需要前台展示极验验证码。
极验验证成功后，把sdk返回的三个结果值一并传入本接口
 * @see http://192.168.2.9:8080/workspace/myWorkspace.do?projectId=119#803
 * @param ok_url {string}  登陆/注册完成后跳转地址 默认/，即账号中心根路径，会再次跳转到uc.36kr.com
 * @param username {string}  登陆账号，手机号或邮箱 手机号格式 国家码+手机号，例如 86+18812345678
 * @param bind {boolean}  是否绑定session中的第三方账号 可选值，默认false
 * @param password {string}  明文密码，由https保护
 * @param type {string}  操作类型，可选值：login,register，表示登陆/注册 可选，如果不传会自动判断
 * @param verifyCode {string}  短信/语音验证码 可选，注册手机号/邮箱时必传
 * @param needCaptcha {boolean}  不需要验证码时传false
 * @param geetest_challenge {string} 需要极验验证的时候使用
 * @param geetest_validate {string} 需要极验验证的时候使用
 * @param geetest_seccode {string} 需要极验验证的时候使用
 *
 * @return resParam {number}  某响应参数
 */
export async function loginOrRegister(params) {
  return request({
    url: '/passport/sign_in',
    method: 'post',
    // transformRequest: [
    //   transformRequestParams,
    // ],
    data: {
      ok_url: params.ok_url,
      username: params.username,
      bind: params.bind,
      password: params.password,
      type: params.type,
      verifyCode: params.verifyCode,
      needCaptcha: params.needCaptcha,
      geetest_challenge: params.geetest_challenge,
      geetest_seccode: params.geetest_seccode,
      geetest_validate: params.geetest_validate,
    },
  });
}

/**
 * 重置密码的发送验证码
 * @param account {string} 账号
 * @param voice {boolean} 是否语音
 * @param type {sms/email} 找回密码的方式, sms短信, email邮箱
 * @param geetest_challenge {string} 需要极验验证的时候使用
 * @param geetest_validate {string} 需要极验验证的时候使用
 * @param geetest_seccode {string} 需要极验验证的时候使用
 */
export function sendResetCode({ type, ...params }) {
  return request({
    url: `/passport/resetpasswd/${type}`,
    method: 'POST',
    // transformRequest: [
    //   transformRequestParams,
    // ],
    data: params,
  });
}

/**
 * 手机号快捷登录的发送验证码
 * @param account {string} 账号
 * @param voice {boolean} 是否语音
 * @param geetest_challenge {string} 需要极验验证的时候使用
 * @param geetest_validate {string} 需要极验验证的时候使用
 * @param geetest_seccode {string} 需要极验验证的时候使用
 */
export function sendCode(params) {
  return request({
    url: '/passport/send_code/sms',
    method: 'POST',
    // transformRequest: [
    //   transformRequestParams,
    // ],
    data: params,
  });
}

/**
 * 获取极验验证码初始化参数 接口会根据请求的user-agent判断是否h5/移动端/pc端，
 * 返回对应的初始化参数。这是由极验本身特性要求的。用户在前端页面操作正确后，极验sdk会返回三个结果数据，需要一并传到要调用的接口中，例如登陆接口/短信验证码接口
 * @see http://192.168.2.9:8080/workspace/myWorkspace.do?projectId=119#801
 *
 * @return gt {string}
 * @return success {number}
 * @return challenge {string}
 */
export function geeTestInit() {
  return request({
    url: `/captcha/v2?t=${Date.now()}`,
    method: 'GET',
  });
}

/**
 * 验证验证码
 * @param code {number} 验证码
 */
export function verifyCode(params) {
  return request({
    url: '/passport/resetpasswd?type=verify',
    method: 'POST',
    data: {
      code: params.code,
    },
  });
}

/**
 * 设置新密码
 * @param passwd {string} 验证码
 */
export function setPassword(params) {
  return request({
    url: '/passport/resetpasswd?type=set',
    method: 'POST',
    data: {
      passwd: params.passwd,
    },
  });
}

/**
 * 区号
 */
export function getCC() {
  return request({
    url: '/dict/cc',
    method: 'GET',
  });
}

/**
 * 获取二维码状态
 * @see http://192.168.2.9:8080/workspace/myWorkspace.do?projectId=119#2447
 * @param token {string}  token
 *
 * @return msg {string}
 * @return data {object}
 * @return code {number}
 */
export function getTokenStatus(params) {
  return request({
    url: '/passport/qrcode/token/status',
    method: 'GET',
    params: {
      token: params.token,
    },
  });
}

/**
 * 获取token接口
 * @see http://192.168.2.9:8080/workspace/myWorkspace.do?projectId=119#2446
 *
 * @return msg {string}
 * @return data {object}
 * @return code {number}
 */
export function getToken() {
  return request({
    url: '/passport/qrcode/token/obtain',
    method: 'GET',
  });
}

export async function checkLogin() {
  return axios({
    url: '/api/mobi-investor/user/checkLogin',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
  });
}
