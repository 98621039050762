const topbar = {
  namespaced: true,
  state: {
    isLivePage: false,
    isShowBar: true,
    isShowLoading: false,
  },
  mutations: {
    SET_IS_LIVE_PAGE: (state, payload) => {
      state.isLivePage = payload;
    },
    SET_IS_SHOW: (state, payload) => {
      state.isShowBar = payload;
    },
    SET_IS_SHOW_LOADING: (state, payload) => {
      state.isShowLoading = payload;
    },
  },
  actions: {
    toggleIsLivePageValue({ commit }, value) {
      commit('SET_IS_LIVE_PAGE', {
        isLivePage: value,
      });
    },
    handleRecordLiveSetting({ commit }, value) {
      if (value) {
        commit('SET_IS_SHOW', false);
        commit('SET_IS_SHOW_LOADING', true);
      } else {
        commit('SET_IS_SHOW', true);
        commit('SET_IS_SHOW_LOADING', false);
      }
    },
  },
};
export default topbar;
