<template>
  <el-dialog
    :visible="visible"
    title="编辑信息"
    append-to-body
    width="468px"
    custom-class="edit-user-center-dialog"
    :close-on-click-modal="false"
    @close="close"
  >
    <div class="dialog-content">
      <div class="dialog-tip">
        <i class="iconfont icon-tips" />完善个人信息，让更多优质用户找到您~
      </div>
      <el-form
        ref="ruleForm"
        :rules="rules"
        :model="lastRuleForm"
        class="form-wrapper"
      >
        <div class="title">
          我的头像
        </div>
        <el-form-item
          prop="avatar"
          class="avatar-upload-wrapper"
        >
          <single-uploader
            class="single-uploader-wrapper"
            :validator="uploadValidator"
            @input="upScuccess"
          >
            <img
              v-if="lastRuleForm.avatar"
              id="avatar"
              :src="lastRuleForm.avatar"
              class="info-avatar-img info-avatar-img-user"
              alt=""
            >
            <div
              v-else
              class="upload-btn-style"
            >
              +
            </div>
          </single-uploader>
          <div class="avatar-upload-tips">
            仅支持jpg、png，小于5M
          </div>
        </el-form-item>
        <div class="title">
          我的姓名
        </div>
        <el-form-item
          prop="name"
        >
          <el-input
            v-model.trim="lastRuleForm.name"
            :disabled="profile.isInvestorPending || isInvestor"
            class="name-edit-wrapper"
            size="small"
          />
          <span
            v-if="isInvestor"
            class="el-form-item__error user-error-item"
          >
            您是平台认证投资人，更改姓名需重新提交身份认证
          </span>
          <span
            v-else-if="profile.isInvestorPending"
            class="el-form-item__error user-error-item"
          >
            您的认证身份正在审核中，暂不支持姓名修改
          </span>
        </el-form-item>
        <div class="title">
          个人介绍
        </div>
        <el-form-item
          prop="brief"
        >
          <el-input
            v-model.trim="lastRuleForm.brief"
            type="textarea"
            size="small"
            :rows="4"
            class="brief-input-wrapper"
          />
          <div
            class="brief-count"
            :class="briefLength>200 ? 'err-brief-count': ''"
          >
            {{ briefLength }}/200
          </div>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer">
      <el-button
        size="mini"
        class="operation-btn cancel-btn"
        @click="$emit('update:visible', false)"
      >
        取消
      </el-button>
      <el-button
        size="mini"
        class="operation-btn"
        type="primary"
        @click="submit"
      >提交</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import SingleUploader from '@/components/JtUploadFile';
import { editUserInfo } from '@/services/user';

export default {
  components: {
    SingleUploader,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const nameRules = (rule, value, callback) => {
      const reg = new RegExp('Mr.', 'g');
      if (!value) {
        callback(new Error('姓名不能为空'));
      } else if (reg.test(value)) {
        callback(new Error('请填写真实姓名'));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        avatar: '',
        name: '',
        brief: '',
      },
      rules: {
        name: [
          { validator: nameRules, trigger: ['change', 'blur'] },
          {
            min: 1, max: 10, message: '请输入10个字符以内的名称', trigger: ['change', 'blur'],
          },
        ],
        brief: [
          {
            min: 1, max: 200, message: '请输入200个字符以内的文字内容', trigger: ['change', 'blur'],
          },
        ],
      },
    };
  },
  computed: {
    ...mapState('user', ['cardInfo', 'profile']),
    ...mapGetters('user', ['isInvestor']),

    lastRuleForm() {
      let name = '';
      const reg = new RegExp('Mr.', 'g');
      if (reg.test(this.cardInfo?.name)) {
        name = '';
      } else {
        name = this.cardInfo.name;
      }
      return Object.assign(this.ruleForm, this.cardInfo, {
        brief: this.cardInfo.intro,
        name,
      });
    },
    briefLength() {
      return this.lastRuleForm.brief?.length || 0;
    },
  },
  methods: {
    ...mapActions('user', [
      'getUserInfo',
    ]),
    editUserInfo,
    async submit() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          const form = {
            name: this.lastRuleForm.name,
            avatar: this.lastRuleForm.avatar,
            brief: this.lastRuleForm.brief,
          };
          if (this.profile.isInvestorPending || this.isInvestor) {
            delete form.name;
          }
          await editUserInfo(form);
          this.getUserInfo();
          this.$message.success('提交信息成功');
          this.close();
        } else {
          return false;
        }
      });
    },
    uploadValidator(file) {
      if (!/^image\//.test(file.type)) {
        this.$message.error('上传格式错误！仅支持jpg、jpeg、png格式！');
        return false;
      }
      if (file.size > 1024 * 1024 * 5) {
        this.$message.error('上传大小超过限制!建议最小尺寸 60px * 60px，并小于5M');
        return false;
      }
      return true;
    },
    async upScuccess(fileUrl) {
      this.lastRuleForm.avatar = fileUrl;
    },
    close() {
      this.$emit('update:visible', false);
    },
  },
};
</script>
<style lang="scss" scoped>

@mixin round-img {
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

/deep/ .el-dialog {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
}

.edit-user-center-dialog {
  .dialog-tip {
    margin: 12px 0 8px;
    font-size: 12px;
    color: #959aa2;
    letter-spacing: 0;
    height: 18px;
    line-height: 18px;

    .iconfont {
      color: #9699ac;
      font-size: 16px;
      margin-right: 5px;
      vertical-align: bottom;
    }
  }

  .el-form.form-wrapper {
    margin: 12px 0;
  }

  .title {
    font-size: 14px;
    color: #333b44;
    line-height: 20px;
    margin-bottom: 8px;
  }

  .form-wrapper {
    .el-form-item {
      margin-bottom: 20px;
    }

    .el-form-item.avatar-upload-wrapper {
      /deep/ .el-form-item__content {
        display: flex;
        align-items: center;
        line-height: 0;
      }
    }

    .info-avatar-img {
      width: 56px;
      height: 56px;
      border: 1px solid #E0E3E6;
      object-fit: cover;

      @include round-img();
    }

    .upload-btn-style {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 56px;
      height: 56px;
      background: #f9fafc;
      border: 1px dashed #e0e3e6;
      border-radius: 4px;
      color: #9799ab;
    }

    .avatar-upload-tips {
      font-size: 14px;
      color: #C1C4CB;
      line-height: 20px;
      margin-left: 16px;
    }
  }

  .brief-input-wrapper {
    position: relative;
  }

  .brief-count {
    position: absolute;
    right: 0;
    bottom: -20px;
    font-family: PingFangSC-Regular;
    font-size: 12px;
    color: #C1C4CB;
    text-align: right;
    line-height: 18px;
  }

  .err-brief-count {
    color: #EC333A;
  }

  .operation-btn {
    font-weight: 400;
  }

  .user-error-item {
    top: 80%;
  }
}
</style>
