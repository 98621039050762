<template>
  <div class="qrcode-login">
    <div
      ref="qrWrapper"
      v-loading="loading"
      class="qrcode-wrapper"
    >
      <canvas
        ref="qrcode"
        class="qrcode"
      />
      <div
        class="refresh-container"
        :class="{active: refreshAvtive}"
        @mouseenter="mouseover = true"
        @mouseleave="mouseover = false"
      >
        <span class="refresh-tip">
          <span @click="handleRefreshToken">
            <i class="iconfont icon-bianzu2" />
          </span>
        </span>
      </div>
    </div>
    <!-- <div class="qrcode-desc">
      请用最新鲸准APP扫码登录
    </div> -->
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import QRCode from 'qrcode';

export default {
  /**
   * 两种场景触发不同行为
   */
  props: {
    scene: {
      type: String,
      default: 'page', // dialog
    },
  },

  data() {
    return {
      mouseover: false,
      loading: true,
    };
  },

  computed: {
    ...mapState('login', [
      'token',
      'expired',
    ]),

    refreshAvtive() {
      return this.expired || this.mouseover;
    },
  },

  watch: {
    token(val) {
      this.drawQrCode(val);
    },
  },

  async mounted() {
    await this.refreshToken();
    this.loading = false;
    this.loopToken({
      scene: this.scene,
    });
  },

  beforeDestroy() {
    this.clearLoopToken();
  },

  methods: {
    ...mapActions('login', [
      'refreshToken',
      'clearLoopToken',
      'loopToken',
    ]),

    drawQrCode(token) {
      const { clientWidth, clientHeight } = this.$refs.qrWrapper;
      QRCode.toCanvas(
        this.$refs.qrcode,
        `${this.$config.rongH5Url}/msgToApp?qrcode_type=login&token=${token}`,
        {
          width: clientWidth,
          height: clientHeight,
          margin: 2,
        },
      );
    },

    async handleRefreshToken() {
      this.loading = true;
      try {
        await this.refreshToken();
        this.mouseover = false;
      } catch (e) {
        const { response } = e;
        const { data } = response;
        this.$message.error(data.msg);
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.qrcode-login {
  padding-top: 60px;
  margin-bottom: 50px;
  text-align: center;

  & .qrcode-wrapper {
    height: 180px;
    width: 180px;
    display: inline-block;
    position: relative;
  }

  & .qrcode {
    width: 100%;
    height: 100%;
  }

  & .qrcode-desc {
    color: #181e26;
    font-size: 12px;
  }

  & .refresh-container {
    width: 100%;
    height: 100%;
    // line-height: 180px;
    // text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.9);
    opacity: 0;
    display: flex;
    align-content: center;
    justify-content: center;

    & .refresh-tip {
      position: relative;
      align-self: center;

      & .iconfont {
        color: #9699ac;
        cursor: pointer;
        font-size: 32px;
        display: none;
      }
    }

    &.active {
      opacity: 1;

      & .iconfont {
        display: block;
      }
    }
  }
}
</style>
