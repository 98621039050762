<template>
  <div class="user-logo-content">
    <div
      class="user-logo-item"
      :class="[className]"
    >
      <slot name="logo">
        <img
          v-if="info && info.avatar"
          class="logo"
          :src="info.avatar"
        >
        <span
          v-else-if="info && !info.avatar"
          class="logo-default"
          :style="{
            background: getRandomColor(info.uid),
          }"
        >
          {{ info.name && info.name[0] || '新' }}
        </span>
      </slot>
    </div>
    <template>
      <!-- 投资者标识 -->
      <i
        v-if="userIsInvestor(info.investorType)"
        class="personal iconfont icon-bianzu8 identity-roles-iconfont"
        :class="isOverLap(info) ?'overlap':''"
      />
      <!-- 服务机构标识 -->
      <i
        v-if="userIsOrg(info.investorType)"
        class="org iconfont icon-bianzu8 identity-roles-iconfont"
        :class="isOverLap(info) ?'overlap':''"
      />
      <!-- 创业者身份标识 -->
      <i
        v-if="userIsEnterpriser(info.enterpriser)"
        class="start iconfont icon-bianzu8 identity-roles-iconfont"
      />
    </template>
  </div>
</template>
<script>
import { userIsInvestor, userIsEnterpriser, userIsOrg } from '@/utils/user.js';
import { getRandomColor } from '@/utils/logoColors';

export default {
  components: {
  },
  props: {
    info: {
      type: Object,
      default: () => ({}),
    },

    className: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
    };
  },
  computed: {},
  methods: {
    getRandomColor,
    userIsInvestor,
    userIsEnterpriser,
    userIsOrg,
    isOverLap(value) {
      if (userIsEnterpriser(value.enterpriser) && userIsInvestor(value.investorType)) return true;
      if (userIsOrg(value.investorType) && userIsEnterpriser(value.enterpriser)) return true;
      return false;
    },
  },
};
</script>
<style lang="scss" scoped>
.user-logo-content {
  position: relative;
  margin-right: 16px;
  margin-top: 1px;

  i.identity-roles-iconfont {
    width: 16px;
    height: 16px;
    line-height: 16px;
    font-size: 14px;
    position: absolute;
    right: -6px;
    bottom: 0;
    border: 1px solid #fff;
    border-radius: 8px;

    &::before {
      margin-left: 0;
    }
  }

  .personal {
    color: #fde4df;
    background-image: linear-gradient(135deg, #575757 0%, #212121 100%, #212121 100%);
  }

  .start {
    color: #212121;
    background-image: linear-gradient(135deg, #fde4df 0%, #edbcb2 100%, #212121 100%);
  }

  .org {
    color: #434a62;
    background-image: linear-gradient(135deg, #e4e9f4 0%, #b3bbcd 100%, #212121 100%);
  }

  .overlap {
    z-index: 1;
    right: 0 !important;
  }

  .user-logo-item {
    width: 40px;
    height: 40px;
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 50%;
    text-align: center;
    line-height: 40px;
    color: #fff;
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    overflow: hidden;
    cursor: pointer;

    > img.logo {
      width: 100%;
      height: 100%;
    }

    .logo-default {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-size: 14px;
    }

    > span.logo {
      width: 100%;
    }
  }
}
</style>
