<template>
  <div>
    <div
      v-if="isShowBar"
      class="header-section"
    >
      <top-bar />
    </div>
    <section
      v-loading="isShowLoading"
      class="app-main"
      element-loading-text="加载中..."
    >
      <router-view />
    </section>
    <div
      v-if="isShowBar"
      class="bottom-section"
    >
      <footer-panel
        v-if="!isLogin"
      />
    </div>

    <login-dialog
      v-if="loginDialogVisible"
    />
    <forget-dialog
      v-if="forgetDialogVisible"
    />
    <privicy-agreement
      :visible="privicyDialogVisible"
      @update:visible="togglePrivicyDialog(false)"
    />
    <service-agreement
      :visible="serviceDialogVisible"
      @update:visible="toggleServiceDialog(false)"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Cookies from 'js-cookie';
import TopBar from '@/views/components/TopBar';
import FooterPanel from '@/views/components/Footer.vue';
import LoginDialog from '@/views/login/LoginDialog';
import ForgetDialog from '@/views/login/ForgetDialog';
import PrivicyAgreement from '@/views/login/PrivicyAgreement';
import ServiceAgreement from '@/views/login/ServiceAgreement';

export default {
  name: 'App',
  components: {
    TopBar,
    FooterPanel,
    LoginDialog,
    ForgetDialog,
    PrivicyAgreement,
    ServiceAgreement,
  },
  computed: {
    ...mapState('user', [
      'isLogin', 'profile',
    ]),
    ...mapState('login', [
      'loginDialogVisible',
      'forgetDialogVisible',
      'privicyDialogVisible',
      'serviceDialogVisible',
    ]),
    ...mapState('topbar', [
      'isShowBar',
      'isShowLoading',
    ]),
  },

  watch: {
    isLogin(val) {
      if (this.loginDialogVisible && val) {
        this.$store.commit('login/SET_LOGIN_DIALOG_ACTIVE', false);
      }
    },
  },
  mounted() {
    document.addEventListener('visibilitychange', this.handleTabChange, false);
  },
  beforeDestroy() {
    window.removeEventListener('visibilitychange', this.handleTabChange, false);
  },
  methods: {
    ...mapActions('login', [
      'showLoginDialog',
      'clearUserState',
      'logOut',
    ]),
    ...mapActions('user', [
      'initUserState',
      'getUserInfo',
    ]),
    handleTabChange() {
      const TOKEN_KEY = 'kr_plus_id';
      const currentToken = Cookies.get(TOKEN_KEY);
      if (document.hidden === false) {
        if (currentToken) {
          // 1:在其他页登录，当前页面未登录，内部详情页有监听login是刷新还是重新请求接口
          if (!Object.keys(this.profile).length) {
            this.handleReloadPage();
            return;
          }
          // 2:当前页与其他页用户不一致，必须刷新页面
          if (Number(currentToken) !== this.profile.uid) {
            this.handleReloadPage();
            return;
          }
          return;
        }
        // 已退出，其他页面未退出
        if (!currentToken && this.profile && this.profile.uid) {
          this.logOut();
        }
      }
    },

    async handleReloadPage() {
      await this.getUserInfo();
      // 当登录弹窗已打开，离开当前页面，在其他页面已登录，回来已登录但登录弹窗还显示问题
      this.$store.dispatch('login/hideLoginDialog');
      window.location.reload();
    },

    togglePrivicyDialog(visible) {
      this.$store.commit('login/SET_PRIVICY_DIALOG_VISIBLE', visible);
    },

    toggleServiceDialog(visible) {
      this.$store.commit('login/SET_SERVICE_DIALOG_VISIBLE', visible);
    },
  },
};
</script>
<style lang="scss" scoped>
.app-main {
  min-height: calc(100vh - 60px);

  /deep/ .el-loading-spinner {
    top: 230px;
  }
}

.login-btn {
  font-size: 20px;
  margin: 50px;
}
</style>
