
/**
 * 不同环境载入不同配置文件
 */
const path = require('path');
const config = {
  env: process.env.NODE_ENV,
  edition: 'LIVE',
  storeVersion: '1.0.0',

  /**
   * oss配置
   */
  oss: {
    public: {
      bucket: 'krplus-pic',
      uploadServer: 'https://v0.api.upyun.com/krplus-pic',
      visiteServer: 'https://pic.jingdata.com',
    },

    private: {
      bucket: 'krplus-priv',
      uploadServer: 'https://v0.api.upyun.com/krplus-priv',
      visiteServer: 'https://krplus-priv.jingdata.com',
    },
  },

  // app 下载地址
  appDownloadUrl: 'https://rong.jingdata.com/m/#/scalchannelcount?channel=jing_data_t',

  /**
   * 目前没有用到
   * 免登录白名单
  */
  loginWhiteList: [
    '/login',
    '/forget',
    '/home',
  ],

};

if (process.env.NODE_ENV === 'development') {
  Object.assign(config, require('./config.dev'));
} else if (process.env.NODE_ENV === 'testing') {
  Object.assign(config, require('./config.test'));
} else {
  Object.assign(config, require('./config.prod'));
}

module.exports = config;
