<template>
  <div class="reset-success">
    <div class="img-wrapper">
      <img
        src="../img/reset_success@2x.png"
        alt="success"
      >
    </div>
    <div class="tip">
      恭喜您，重置密码成功
    </div>
    <el-button
      class="back-btn"
      @click="toLogin"
    >
      返回登录({{ count }}S)
    </el-button>
  </div>
</template>

<script>
export default {
  props: {
    /**
     * 两种场景触发不同行为
     */
    scene: {
      type: String,
      default: 'page', // dialog
    },
  },
  data() {
    return {
      count: 3,

      timerId: '',
    };
  },

  mounted() {
    this.countDown();
  },

  methods: {
    toLogin() {
      clearTimeout(this.timerId);
      if (this.scene === 'dialog') {
        this.$store.dispatch('login/toggleForgetDialogVisible', false);
        this.$nextTick(() => {
          this.$store.dispatch('login/showLoginDialog', {
            type: 'password',
          });
        });
      }
    },
    countDown() {
      this.timerId = setTimeout(() => {
        this.count -= 1;
        if (this.count >= 1) {
          this.countDown();
        } else {
          this.toLogin();
        }
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.reset-success {
  text-align: center;
  padding-top: 150px;

  & .img-wrapper {
    font-size: 0;
  }

  & img {
    width: 80px;
  }

  & .tip {
    margin-top: 30px;
    font-size: 14px;
    color: #333b44;
    letter-spacing: 0;
    line-height: 20px;
  }

  & .back-btn {
    height: 30px;
    margin-top: 16px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 110px;
    font-size: 14px;
    // padding-left: 0;
    // padding-right: 0;
  }
}
</style>
