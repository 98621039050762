import Cookies from 'js-cookie';
import { Message } from 'element-ui';
import * as loginService from '@/services/login';
import * as userService from '@/services/user';

const LOGIN_SUCCESS_CODE = 2;
const EXPIRED_CODE = 3;

const TOKEN_KEY = 'kr_plus_id';

let loopId = 0;

const user = {
  namespaced: true,

  state: {
    expired: false,
    token: '',

    loginDialogVisible: false, // 登录弹窗展示状态
    loginDialogActiveTab: 'sms', // 登录类型
    loginCallback: null,

    forgetDialogVisible: false, // 忘记密码弹窗

    privicyDialogVisible: false, // 隐私条款
    serviceDialogVisible: false, // 服务条款
  },

  mutations: {
    UPDATE_STATE: (state, paylod) => {
      Object.assign(state, paylod);
    },

    SET_LOGIN_DIALOG_ACTIVE: (state, payload) => {
      state.loginDialogActiveTab = payload;
    },

    SET_LOGIN_DIALOG_VISIBLE: (state, payload) => {
      state.loginDialogVisible = payload;
    },

    SET_PRIVICY_DIALOG_VISIBLE: (state, payload) => {
      state.privicyDialogVisible = payload;
    },

    SET_SERVICE_DIALOG_VISIBLE: (state, payload) => {
      state.serviceDialogVisible = payload;
    },
  },

  actions: {

    showLoginDialog({ commit }, payload) {
      commit('UPDATE_STATE', {
        loginDialogVisible: true,
        loginDialogActiveTab: payload?.type || 'sms',
        /**
         * 登录成功后的行为
         * 字符串为内置行为
         * push:url 登录成功后跳转
         * tagPush:url 增加页签标识的push
         *
         * 其他行为传递方法，登录成功后执行
         */
        loginCallback: payload?.callback || null,

      });
    },
    hideLoginDialog({ commit }) {
      commit('UPDATE_STATE', {
        loginDialogVisible: false,
        loginDialogActiveTab: 'sms',
        loginCallback: null,
      });
    },

    toggleForgetDialogVisible({ commit }, visible) {
      commit('UPDATE_STATE', {
        forgetDialogVisible: visible,
      });
    },

    // 登出
    async logOut({ dispatch, commit }, returnTo) {
      try {
        const data = await userService.logout({
          return_to: returnTo,
        });
        if (data && !Object.keys(data).length) {
          // 清理各种状态
          // eslint-disable-next-line
          const { currentRoute } = __g_router__;
          dispatch('clearUserState');
          await dispatch('hideLoginDialog');
          await commit('user/SET_LOGIN', false, {
            root: true,
          });
          if (currentRoute.path !== '/home') {
            // 跳转到首页再清理状态，避免页面已登录状态缓存？（更改为先清状态再跳转至首页。不知会不会有雷）
            __g_router__.replace({
              path: '/home',
            });
          }
          window.location.reload();
        }
      } catch (error) {
        // console.log(error);
      }
    },

    // 登录或注册
    async login({
      dispatch,
    }, payload) {
      await loginService.loginOrRegister(payload);
      const res = await userService.getUserIdentiry();
      if (res) {
        dispatch('runCallback');
        dispatch('hideLoginDialog');
      }
      await dispatch('user/getUserInfo', null, {
        root: true,
      });
      await dispatch('user/initUserState', null, {
        root: true,
      });
      window.location.reload();
    },

    redirect() {
      const path = location.href.split('?redirect=');
      // storage需要重新创建，重新初始化状态，刷新页面
      if (path[1]) {
        window.__g_router__.push(decodeURIComponent(path[1]));
        // location.href = `/#${decodeURIComponent(path[1])}`;
      } else {
        window.__g_router__.push('/home');
        // location.href = '/#/home';
      }
    },

    runCallback({ state }) {
      const { loginCallback } = state;
      if (typeof loginCallback === 'string') {
        const [action, arg] = loginCallback.split(':');

        // 路径跳转
        if (action === 'push') {
          window.__g_router__.push(arg);
        }
      } else if (typeof loginCallback === 'function') {
        loginCallback();
      }
    },
    /**
     * 清理登陆用户个人信息
     */
    async clearUserState({ commit }) {
      Cookies.remove('kr_plus_token', {
        path: '/',
        domain: '.capitalcoffee.club',
      });
      Cookies.remove('uitoken', {
        path: '/',
        domain: '.capitalcoffee.club',
      });
      Cookies.remove(TOKEN_KEY, {
        path: '/',
        domain: '.capitalcoffee.club',
      });
      commit('user/RESET_STATE', null, {
        root: true,
      });
    },

    /**
     * 获取token
     * @param {*} param0
     * @param {*} params
     */
    async refreshToken({ commit }) {
      const { data: resData } = await loginService.getToken();
      if (resData.code === 0) {
        commit('UPDATE_STATE', {
          expired: false,
          token: resData.data.token,
        });
      }
    },

    async loopToken({ dispatch, commit, state }, payload) {
      const { scene } = payload;
      loopId = setInterval(async () => {
        if (!state.token) {
          return;
        }
        const { data } = await loginService.getTokenStatus({
          token: state.token,
        });

        if (data?.data?.status === LOGIN_SUCCESS_CODE) {
          try {
            const res = await userService.getUserIdentiry();
            if (res && Object.keys(res).length) {
              await dispatch('user/getUserInfo', null, { root: true });
              Message.success('登录成功');
              if (scene === 'dialog') {
                dispatch('runCallback');
                dispatch('hideLoginDialog');
              }
              window.location.reload();
              // 暂未发现有场景用到
              // else {
              //   dispatch('redirect');
              // }
            }
          } catch (error) {
            Message.error(error?.msg || '后台出错，请稍后再试');
          }
        }

        if (data?.data?.status === EXPIRED_CODE) {
          commit('UPDATE_STATE', {
            expired: true,
          });
        }
      }, 5000);
    },

    async clearLoopToken() {
      clearInterval(loopId);
    },
  },
};

export default user;
