<template>
  <div
    class="comp-home-top"
    :class="{
      'is-live-comp-home-top': isLivePage
    }"
  >
    <div class="comp-home-top-content">
      <div class="top-menu-logo">
        <img
          v-if="!isLivePage"
          src="@/assets/img/logo1@2x.png"
          @click="handleToHomePage"
        >
        <img
          v-else
          src="@/assets/img/logo2@2x.png"
          @click="handleToHomePage"
        >
      </div>
      <!-- 鲸准 -->
      <div class="top-menu-right">
        <div
          class="top-menu-right-item jingzhun-item"
          @click="handleToCloud"
        >
          <i class="iconfont icon-whale" />鲸准
        </div>
        <!-- 帮助中心 -->
        <div
          class="top-menu-right-item"
          @click="toggleHelpDialog(true)"
        >
          <i class="iconfont icon-v_help" />帮助中心
        </div>

        <div
          class="top-menu-right-item"
        >
          <el-popover
            placement="bottom"
            trigger="hover"
            popper-class="download-app-wrapper"
          >
            <canvas id="canvas" />
            <div slot="reference">
              <div class="bottom-item-li">
                <li class="bottom-item-li-icon">
                  <i class="iconfont icon-v_app" />下载APP
                </li>
              </div>
            </div>
          </el-popover>
        </div>

        <user-center
          v-if="isLogin && profile.uid"
          class="user-center"
        />
        <el-button
          v-else
          type="primary"
          class="login-button"
          size="small"
          @click="handleClickLogin()"
        >
          登录
        </el-button>
      </div>
    </div>
    <help-center
      :visible="helpDialogVisible"
      @update:visible="toggleHelpDialog(false)"
    />
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import QRCode from 'qrcode';
import { environmentHref, cloudRequestPath } from '@/utils/cloudRequest';
import { getCloudToken } from '@/services/home';
import HelpCenter from './HelpCenter.vue';
import UserCenter from './UserCenter.vue';

export default {
  components: {
    HelpCenter,
    UserCenter,
  },
  data() {
    return {
      helpDialogVisible: false,
    };
  },
  computed: {
    ...mapState('user', ['isLogin', 'profile']),
    ...mapState('topbar', ['isLivePage']),
  },

  mounted() {
    if (this.isLogin) {
      this.getUserInfo();
    }

    const canvas = document.getElementById('canvas');
    QRCode.toCanvas(canvas,
      this.$config.appDownloadUrl,
      {
        margin: 0,
        width: 80,
        height: 80,
      },
      (error) => {
        if (error) {
          this.$message.error(error.message);
        }
      });
  },
  methods: {
    ...mapActions('login', [
      'logOut', 'showLoginDialog',
    ]),
    ...mapActions('user', [
      'getUserInfo',
    ]),
    handleToHomePage() {
      const { path } = this.$route;
      if (path === '/home') return;
      this.$router.push('/home');
    },
    // 触发登录弹窗
    handleClickLogin() {
      this.$nextTick(() => {
        // const toNext = () => {
        //   if (['liveroom', 'replay'].includes(this.$route.name)) {
        //     window.location.reload();
        //   }
        // };
        this.showLoginDialog();
      });
    },
    toggleHelpDialog(value) {
      this.helpDialogVisible = value;
    },
    // 跳转至个人版
    async handleToCloud() {
      if (!this.isLogin) {
        this.showLoginDialog();
        return;
      }
      try {
        const token = await getCloudToken();
        const fullPath = `${environmentHref}/#/home`;
        window.open(`${cloudRequestPath}&token=${token}&redirectUrl=${fullPath}`, 'cloud');
      } catch (error) {
        // console.log(error)
      }
    },
  },

};
</script>
<style lang="scss" scoped>
  .comp-home-top {
    width: 100%;
    height: 60px;
    box-shadow: 0 1px 8px 0 rgba(39, 54, 78, 0.08), inset 0 -1px 0 0 rgba(228,229,235,0.60);

    .comp-home-top-content {
      width: 1100px;
      height: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .top-menu-logo {
      width: 126px;
      height: 26px;
      cursor: pointer;

      > img {
        width: 100%;
        height: 100%;
      }
    }

    .top-menu-right {
      display: flex;
      align-items: center;

      .top-menu-right-item {
        margin-right: 16px;
        line-height: 16px;
        font-size: 14px;
        color: #959aa2;

        &:hover {
          cursor: pointer;
        }

        .iconfont {
          vertical-align: bottom;
          margin-right: 4px;
        }
      }
    }
  }

  .is-live-comp-home-top {
    background: #0c111e;
    box-shadow: none;

    .user-center {
      /deep/ .user-name,
      /deep/ .drop-icon {
        color: #fff !important;
      }
    }

    .top-menu-right-item {
      &:hover {
        color: #fff;
      }
    }
  }

  .bottom-item-li {
    display: flex;

    li {
      list-style: none;
    }
  }

  .login-button {
    width: 80px;
    font-size: 14px;
    letter-spacing: 2px;
  }

</style>
<style lang="scss">
  .el-popover.download-app-wrapper {
    top: 40px !important;
    padding: 5px;
    height: 90px;
    width: 90px;
    min-width: 90px;

    .el-popover__reference {
      .bottom-item-li {
        display: flex;
      }
    }
  }

  .is-live-comp-home-top {
    .top-menu-user-center {
      .user-name {
        color: #fff;
      }
    }
  }
</style>
