const logoColors = [
  '#5BD0D0',
  '#A38EF1',
  '#F8A75B',
  '#3EC58F',
  '#609DF6',
  '#90CD6A',
  '#6CBF6C',
  '#8CA8C2',

  '#EE8AC2',
  '#F3907F',
  '#FF8181',
  '#C0894E',
  '#46BBE9',
  '#D195E7',
  '#DBC179',
  '#7B86E4',
];

export default logoColors;

export const getRandomColor = (uid) => {
  let randomIndex = '';
  if (uid) {
    const powerNum = (`${uid}`).length;
    const uidIndex = uid / (10 ** powerNum);
    randomIndex = Math.floor(uidIndex * logoColors.length);
  } else {
    randomIndex = Math.floor(Math.random() * logoColors.length);
  }
  return logoColors[randomIndex];
};
