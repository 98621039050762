import Cookies from 'js-cookie';
import TopBarThemeList from './TopBarThemeList';

const TOKEN_KEY = 'kr_plus_id';
const isLogins = !!Cookies.get(TOKEN_KEY);
export default (router) => {
  /**
   * 初始化登录状态，拦截无法访问路由
   */
  router.beforeEach(async (to, from, next) => {
    __g_store__.commit('user/SET_LOGIN', isLogins);
    // const { isLogin, profile } = __g_store__.state.user;

    // 页面录制页面隐藏topbar
    if (to.name === 'recordLive') {
      await __g_store__.dispatch('topbar/handleRecordLiveSetting', true);
    } else {
      await __g_store__.dispatch('topbar/handleRecordLiveSetting', false);
    }
    // 控制非首页的topbar样式
    if (TopBarThemeList.includes(to.path)) {
      await __g_store__.commit('topbar/SET_IS_LIVE_PAGE', false);
      next();
    } else {
      await __g_store__.commit('topbar/SET_IS_LIVE_PAGE', true);
      next();
    }
    // if (!isLogin && profile.uid) {
    //   await __g_store__.dispatch('login/clearUserState');
    //   next();
    //   return;
    // }
    // todo: 为啥要加这个逻辑
    // 从cloud带cookie跳转过来，若无用户信息自动获取
    // if (isLogin && !profile.uid) {
    //   await __g_store__.dispatch('user/getUserInfo');
    //   next();
    // }

    next();
  });

  /**
    * 页面访问埋点逻辑
  */
  router.afterEach((to, from) => {
    const { autoTrack, trackProps } = to.meta;
    const { profile } = __g_store__.state.user;
    let props = {};
    if (typeof trackProps === 'function') {
      props = trackProps(to, from);
    } else {
      props = trackProps;
    }

    if (sensors && autoTrack !== false) {
      setTimeout(() => {
        sensors.quick('autoTrackSinglePage', {
          ...props,
          consumer_id: profile?.uid || null,
          创业者_是否: profile?.enterpriser || null,
          投资人_类型: profile?.investorType || null,
        });
      }, 0);
    }
  });
};
