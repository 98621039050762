<template>
  <div class="home-footer">
    <div class="home-footer-content">
      <div class="service-information">
        <div class="left">
          <a
            href="https://www.jingdata.com/"
            target="_blank"
          >关于鲸准</a>
          <span @click="toggleServiceDialog(true)">用户服务协议</span>
          <span @click="togglePrivicyDialog(true)">隐私条款</span>
        </div>
        <ul class="img-list">
          <li>
            <img
              class="code"
              src="/img/footer/app.png"
            >
            <p>鲸准APP</p>
          </li>
          <li>
            <img
              class="code"
              src="/img/footer/xiaochengxu.png"
            >
            <p>鲸准小程序</p>
          </li>
          <li>
            <img
              class="code"
              src="/img/footer/public.png"
            >
            <p>Jingdata 公众号</p>
          </li>
        </ul>
      </div>
      <div class="contact-information">
        <div class="left">
          <img
            class="logo"
            src="/img/footer/jingdata.png"
          >
          <p>数字化助力投资决策与产业创新</p>
        </div>
        <div class="right">
          <p class="contact">
            <span>邮箱：bd@jingdata.com</span>
            <span>电话：400-626-5050</span>
          </p>
          <p>北京创业光荣信息科技有限责任公司 版权所有 京ICP备17035618号 京公网安备案 11010502036749</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
    togglePrivicyDialog(visible) {
      this.$store.commit('login/SET_PRIVICY_DIALOG_VISIBLE', visible);
    },
    toggleServiceDialog(visible) {
      this.$store.commit('login/SET_SERVICE_DIALOG_VISIBLE', visible);
    },
  },

};
</script>

<style lang="scss" scoped>
  .home-footer {
    background: #353d4e;
    border: 1px solid #979797;
    width: 100%;
    height: 258px;
    padding: 40px 0;

    .home-footer-content {
      width: 1200px;
      margin: 0 auto;
    }

    .service-information {
      display: flex;

      .img-list {
        flex: 1;
        text-align: right;

        .code {
          width: 80px;
        }

        li {
          display: inline-block;
          width: 104px;
          height: 108px;
          margin-right: 40px;
          text-align: center;

          &:last-child {
            margin-right: 0;
          }

          >p {
            font-size: 14px;
            color: rgba(255, 255, 255, 0.5);
            text-align: center;
            line-height: 20px;
            margin-top: 4px;
          }
        }
      }

      .left {
        >span,
        >a {
          font-size: 14px;
          color: rgba(255, 255, 255, 0.5);
          line-height: 20px;
          font-weight: 500;
          cursor: pointer;

          &:hover {
            color: rgba(255, 255, 255, 0.8);
          }

          &:nth-child(2) {
            border-left: 2px solid rgba(255, 255, 255, 0.2);
            border-right: 2px solid rgba(255, 255, 255, 0.2);
            margin: 0 20px;
            padding: 0 20px;
          }
        }
      }
    }

    .contact-information {
      display: flex;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.3);
      line-height: 18px;

      .logo {
        width: 168px;
        margin-bottom: 8px;
      }

      .right {
        margin-left: 33px;
        padding-top: 11px;

        .contact {
          margin-bottom: 18px;
        }
      }
    }
  }

</style>
