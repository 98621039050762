<template>
  <el-dropdown
    trigger="hover"
    placement="bottom-end"
    popper-class="top-menu-user-center"
    @visible-change="handleOpen"
  >
    <div class="top-menu-user-center-out">
      <logo-panel
        :info="profile"
      />
      <span class="user-name">
        {{ profile.name || '新用户' }}
      </span>
      <i class="iconfont drop-icon icon-v_caret-down" />
    </div>
    <el-dropdown-menu
      slot="dropdown"
      class="top-menu-dropdown-wrapper"
    >
      <el-dropdown-item>
        <div class="top-menu-dropdown-logo-item">
          <logo-panel
            :info="cardInfo"
          />
          <div class="logo-item-user-info">
            <div class="user-name">
              {{ cardInfo.name || '新用户' }}
            </div>
            <div class="company-position">
              <span
                v-if="cardInfo.org && cardInfo.position"
              >
                {{ cardInfo.org }} - {{ cardInfo.position }}
              </span>
              <span
                v-else-if="cardInfo.org && !cardInfo.position"
              >
                {{ cardInfo.org }}
              </span>
              <span
                v-else-if="!cardInfo.org && cardInfo.position"
              >
                {{ cardInfo.position }}
              </span>
              <span v-else> - </span>
            </div>
            <div class="fans-information">
              <span>
                <span class="number">{{ cardInfo.fans || '0' }}</span>粉丝
              </span>
              <span>
                <span class="number focus-number">{{ cardInfo.follows || '0' }}</span>关注
              </span>
            </div>
          </div>
        </div>
        <div class="top-menu-dropdown-introduction-item">
          {{ cardInfo.intro || '点击“编辑信息”来写点自我介绍吧~' }}
        </div>
        <div class="top-menu-dropdown-operation-item">
          <div
            class="edit-info-button"
            @click="toggleEditUserCenterDialog(true)"
          >
            <i class="iconfont icon-v_edit" />
            编辑信息
          </div>
          <div
            class="logout-button"
            @click="handleLogOut"
          >
            <i class="iconfont icon-v_exit" />
            退出登录
          </div>
        </div>
      </el-dropdown-item>
    </el-dropdown-menu>
    <edit-user-center-dialog
      :visible="userInfoDialogVisible"
      @update:visible="toggleEditUserCenterDialog(false)"
    />
  </el-dropdown>
</template>
<script>
import {
  mapState, mapActions,
} from 'vuex';
import LogoPanel from '@/views/components/LogoPanel.vue';
import EditUserCenterDialog from './EditUserCenterDialog.vue';

export default {
  components: {
    LogoPanel,
    EditUserCenterDialog,
  },
  computed: {
    ...mapState('user', ['isLogin', 'profile', 'cardInfo', 'userInfoDialogVisible']),
  },
  methods: {
    ...mapActions('login', [
      'logOut',
    ]),
    ...mapActions('user', [
      'getUserInfo',
      'updateCardInfo',
    ]),

    // 在其他页面更改卡片信息，当打开用户卡片时需要重新刷新用户卡片信息
    handleOpen(visible) {
      if (visible && this.isLogin) {
        this.updateCardInfo();
      }
    },
    handleLogOut() {
      this.logOut();
    },
    toggleEditUserCenterDialog(visible) {
      this.$store.commit('user/SET_USERINFO_DIALOG_VISIBLE', visible);
    },
  },
};
</script>
<style lang="scss" scoped>
.top-menu-user-center-out {
  display: flex;
  align-items: center;

  .user-name {
    display: inline-block;
    max-width: 140px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  /deep/ .user-logo-item {
    width: 30px;
    height: 30px;

    > img {
      width: 100% !important;
      height: 100% !important;
    }
  }

  .user-logo-content {
    margin-right: 8px;
  }

  .drop-icon {
    margin-left: 4px;
  }
}
</style>
<style lang="scss">
.el-popper.top-menu-dropdown-wrapper {
  padding: 14px;
  width: 378px;
  top: 40px !important;

  .el-dropdown-menu__item {
    padding: 0;
  }

  .el-dropdown-menu__item:not(.is-disabled):hover,
  .el-dropdown-menu__item:focus {
    background-color: #fff;
    color: #333b44;
    cursor: default;
  }

  .top-menu-dropdown-logo-item {
    display: flex;
    align-items: center;

    .user-logo-item {
      width: 66px;
      height: 66px;

      > img.logo {
        width: 100%;
        height: 100%;
      }
    }

    .logo-item-user-info {
      display: flex;
      flex: 1;
      width: 0;
      flex-direction: column;

      .user-name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: 700;
        font-size: 14px;
        color: #333b44;
        line-height: 20px;
      }

      .company-position {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-top: 2px;
        font-size: 12px;
        color: #666b71;
        line-height: 18px;
      }

      .fans-information {
        margin-top: 2px;
        font-size: 12px;
        color: #959aa2;
        line-height: 18px;
      }

      .number {
        font-size: 14px;
        color: #333b44;
        margin-right: 4px;
      }

      .number.focus-number {
        margin-left: 16px;
      }
    }
  }

  .top-menu-dropdown-introduction-item {
    margin: 16px 0;
    font-size: 12px;
    color: #666b71;
    letter-spacing: 0;
    line-height: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    // white-space: nowrap;
  }

  .top-menu-dropdown-operation-item {
    display: flex;
    justify-content: space-between;

    .edit-info-button,
    .logout-button {
      font-size: 12px;
      color: #959aa2;
      letter-spacing: 0;
      line-height: 18px;

      > i {
        vertical-align: bottom;
        font-size: 16px;
        margin-right: 4px;
      }

      &:hover {
        color: #3e74ca;
      }
    }
  }
}
</style>
