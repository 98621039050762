import _ from 'lodash';
import { getRoomUserList } from '@/services/room';

const room = {
  namespaced: true,
  state: {
    roomInfo: {},
    popUserInfo: {},

    guestList: [], // 嘉宾
    followList: [], // 讲话者关注的人
    otherWatcherList: [], // 其他观众
    userInfo: {}, // 当前用户信息
    prevFollowList: [], // 上一次讲话者关注的人
    liveStreamInfo: {}, // 直播平台类型（0站内直播｜1第三方视频流）
  },
  mutations: {
    SET_LIVE_INFO: (state, payload) => {
      state.roomInfo = payload;
    },
    SET_POP_USER_INFO: (state, payload) => {
      state.popUserInfo = payload;
    },
    SET_GUEST_LIST: (state, payload) => {
      state.guestList = payload;
    },
    SET_FOLLOW_LIST: (state, payload) => {
      state.followList = payload;
    },
    SET_OTHER_WATCHER_LIST: (state, payload) => {
      state.otherWatcherList = payload;
    },
    SET_CURRENT_USER_INFO: (state, payload) => {
      state.userInfo = payload;
    },
    SET_PREV_FOLLOW_LIST: (state, payload) => {
      state.prevFollowList = payload;
    },
    SET_LIVE_STREAM_INFO: (state, payload) => {
      state.liveStreamInfo = payload;
    },
  },
  actions: {
    setLiveInfoState({ commit }, value) {
      commit('SET_LIVE_INFO', value);
    },
    setLiveUserPopState({ commit }, value) {
      commit('SET_POP_USER_INFO', value);
    },

    // 获取直播间用户列表
    async getLiveUserList({ commit }, value) {
      const result = await getRoomUserList(value);
      const {
        guests, followUser, otherWatcher, currentUser,
      } = result;
      commit('SET_GUEST_LIST', guests || []);
      commit('SET_FOLLOW_LIST', followUser || []);
      commit('SET_OTHER_WATCHER_LIST', otherWatcher || []);
      commit('SET_PREV_FOLLOW_LIST', followUser || []);
      if (currentUser) {
        commit('SET_CURRENT_USER_INFO', currentUser);
      }
    },

    /**
     *
     * 收到后端发送的用户进入房间rtm频道消息
     * 注：嘉宾需要依次按照liveUserType，createTime升序排列
     * liveUserType: 房间内权限：0管理员｜1嘉宾｜2普通用户
     * attentionStatus: 是否是嘉宾关注的人（默认值0否，1是）
     */
    handleServerClientMemberEntered({ commit, state, dispatch }, value) {
      const {
        liveUserType, uid, attentionStatus, followUser,
      } = value;
      const { guestList, otherWatcherList } = state;

      if (liveUserType === 0 || liveUserType === 1) { // 管理员 or 嘉宾
        // 将用户添加到嘉宾席，并排序
        let list = guestList.filter((item) => { return item.uid !== uid; });
        list.push(value);
        list = _.orderBy(list, ['liveUserType', 'createTime']);
        commit('SET_GUEST_LIST', list);

        dispatch('removeFollowListFromWatcherList', value); // 从其他观众席中去掉讲话者关注的人
      }

      if (liveUserType === 2) { // 观众
        if (attentionStatus === 0) { // 其他观众
          // 将观众添加到其他观众席
          let list = otherWatcherList.filter((item) => { return item.uid !== uid; });
          list = [value, ...list];
          commit('SET_OTHER_WATCHER_LIST', list);
        }
        dispatch('removeFollowListFromWatcherList', value); // 从其他观众席中去掉讲话者关注的人
      }

      commit('SET_FOLLOW_LIST', followUser);
      commit('SET_PREV_FOLLOW_LIST', followUser);
    },

    /**
     *
     * 收到后端发送的用户离开房间rtm频道消息，将用户从所属列表中删掉
     * 注：嘉宾需要依次按照liveUserType，createTime升序排列
     * liveUserType: 房间内权限：0管理员｜1嘉宾｜2普通用户
     * attentionStatus: 是否是嘉宾关注的人（默认值0否，1是）
     */
    handleServerClientMemberLeft({ commit, state, dispatch }, value) {
      const {
        liveUserType, uid, attentionStatus, followUser,
      } = value;
      const {
        guestList, followList, otherWatcherList,
      } = state;

      if (liveUserType === 1) { // 嘉宾
        // 将该用户从嘉宾席中删掉
        let list = guestList.filter((item) => { return item.uid !== uid; });
        list = _.orderBy(list, ['liveUserType', 'createTime']);
        commit('SET_GUEST_LIST', list);

        commit('SET_FOLLOW_LIST', followUser);
        dispatch('handlePrevFollow', value);
      }

      if (liveUserType === 2) { // 观众
        if (attentionStatus === 1) { // 讲话者关注的人
          const list = followList.filter((item) => { return item.uid !== uid; });
          commit('SET_FOLLOW_LIST', list);
          commit('SET_PREV_FOLLOW_LIST', list);
        } else { // 其他观众
          const list = otherWatcherList.filter((item) => { return item.uid !== uid; });
          commit('SET_OTHER_WATCHER_LIST', list);
        }
      }
    },

    /**
     *
     * 之前嘉宾关注的人，需要重新放进观众席中
     * 情况包括：嘉宾离开房间，嘉宾身份改为观众
     */
    handlePrevFollow({ commit, state }, value) {
      const { followUser } = value;
      const { prevFollowList, otherWatcherList } = state;

      const extraOtherWatcherList = prevFollowList.filter((item) => { // 之前嘉宾关注的人
        return !followUser.some((ele) => ele.uid === item.uid);
      });
      const otherWatchers = extraOtherWatcherList.concat(otherWatcherList);
      commit('SET_OTHER_WATCHER_LIST', otherWatchers);
      commit('SET_PREV_FOLLOW_LIST', followUser);
    },

    /**
     *
     * 从其他观众席中去掉讲话者关注的人
     */
    removeFollowListFromWatcherList({ commit, state }, value) {
      const { prevFollowList, otherWatcherList } = state;
      const { followUser } = value;

      const list = [...prevFollowList, ...otherWatcherList];
      let otherWatchers = list.filter((item) => {
        return !followUser.some((ele) => ele.uid === item.uid);
      });
      otherWatchers = _.uniqBy(otherWatchers, 'uid');
      commit('SET_OTHER_WATCHER_LIST', otherWatchers);
      return otherWatchers;
    },

    /**
     *
     * 收到后端发送的用户开闭麦频道消息
     * 包含本人开关麦和将他静音
     */
    handleServerClientMicChanged({ commit, state }, value) {
      const { speech, uid } = value;
      const { guestList } = state;
      const list = _.cloneDeep(guestList);
      list.forEach((item) => {
        if (item.uid === uid) {
          item.speech = speech;
        }
      });
      commit('SET_GUEST_LIST', list); // 更改嘉宾席用户开关麦图标ui状态
    },

    /**
     *
     * 设置个人信息中的状态
     * speech: 是否开麦（0否｜1是）
     * roomAction: 0无权限，1操作ppt
     */
    setUserStatus({ commit, state }, status = {}) {
      const userInfo = Object.assign(_.cloneDeep(state.userInfo), status);
      commit('SET_CURRENT_USER_INFO', userInfo);
    },

    /**
     *
     * 修改直播间基本信息
     * handsType: 0-关闭举手 1-开启举手
     * likeTotalNumber: 点赞数
     */
    setLiveInfoStatus({ commit, state }, status = {}) {
      const roomInfo = Object.assign(_.cloneDeep(state.roomInfo), status);
      commit('SET_LIVE_INFO', roomInfo);
    },

    /**
     *
     * 收到后端发送的PPT授权/取消授权rtm频道消息
     * liveUserType: 房间内权限：0管理员｜1嘉宾｜2普通用户
     * roomAction: 0无权限，1操作ppt
     */
    handleServerClientPPTChanged({ commit, state, dispatch }, value) {
      const { roomAction, uid } = value;
      const { guestList, userInfo } = state;

      // 嘉宾修改个人ppt权限
      if (userInfo.liveUserType === 1) {
        if (userInfo.uid === uid) { // 授权/取消授权的是本人
          dispatch('setUserStatus', { roomAction });
        } else { // 其他嘉宾取消ppt权限
          dispatch('setUserStatus', { roomAction: 0 });
        }
      }

      const list = _.cloneDeep(guestList);
      list.forEach((item) => {
        if (item.liveUserType === 1) { // 嘉宾
          item.roomAction = 0;
        }
        if (item.uid === uid) {
          item.roomAction = roomAction;
        }
      });
      commit('SET_GUEST_LIST', list);
    },

    /**
     *
     * 收到后端发送的身份变更的rtm频道消息
     * 包括情况：管理员设置嘉宾为观众，观众同意成为嘉宾，嘉宾设置自己为观众
     * 注：嘉宾需要依次按照liveUserType，createTime升序排列
     * liveUserType: 房间内权限：0管理员｜1嘉宾｜2普通用户
     * attentionStatus: 是否是嘉宾关注的人（默认值0否，1是）
     */
    async handleServerClientMemberTypeChanged({ commit, state, dispatch }, value) {
      const {
        liveUserType, uid, attentionStatus, followUser, speech, roomAction,
      } = value;
      const {
        guestList, otherWatcherList, userInfo,
      } = state;

      if (userInfo.uid === uid) { // 身份变更的是本人
        dispatch('setUserStatus', { liveUserType, speech, roomAction });
      }

      // 管理员设置嘉宾为观众 or 嘉宾设置自己为观众
      if (liveUserType === 2) {
        // 将该用户从嘉宾席中删掉
        const list = guestList.filter((item) => { return item.uid !== uid; });
        commit('SET_GUEST_LIST', list);

        if (attentionStatus === 0) { // 如果不是关注的人：放入其他观众席
          const otherWatchers = [value, ...otherWatcherList];
          commit('SET_OTHER_WATCHER_LIST', otherWatchers);
        }
        dispatch('handlePrevFollow', value);
      }

      // 观众同意成为嘉宾
      if (liveUserType === 1) {
        let list = _.cloneDeep(guestList);
        list.push(value);
        list = _.orderBy(list, ['liveUserType', 'createTime']);
        commit('SET_GUEST_LIST', list);
        commit('SET_PREV_FOLLOW_LIST', followUser);

        // 当前嘉宾如果不是关注的人，从其他观众席删掉
        if (attentionStatus === 0) {
          const otherWatchers = otherWatcherList.filter((item) => { return item.uid !== uid; });
          commit('SET_OTHER_WATCHER_LIST', otherWatchers);
        }
        dispatch('removeFollowListFromWatcherList', value); // 从其他观众席中去掉讲话者关注的人
      }

      commit('SET_FOLLOW_LIST', followUser);
    },

  },
  getters: {
    isLiveStream: (state) => {
      return state.liveStreamInfo?.livePlatformType === 1;
    },
  },
};
export default room;
