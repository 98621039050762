type ProgressStatus = 'start' | 'running' | 'end';

interface onProgressFunc {
  (status: ProgressStatus, current: number): void;
}

/**
 * 倒计时
 */
class CDown {
  onProgress: onProgressFunc;

  private timerId: number;

  private duration: number;

  private interval: number;

  private current: number;

  constructor({
    duration = 60,
    interval = 1,
    onProgress = () => undefined,
  } = {}) {
    this.timerId = 0;
    this.duration = duration;
    this.interval = interval;
    this.onProgress = onProgress;
    this.current = duration;
  }

  start(): void {
    this.reset();
    this.onProgress.call(this, 'start', this.current);
    const run = () => {
      this.timerId = setTimeout(() => {
        this.current -= 1;
        if (this.current > 0) {
          this.onProgress.call(this, 'running', this.current);
          run();
        } else {
          this.onProgress.call(this, 'end', this.current);
        }
      }, this.interval * 1000);
    };
    run();
  }

  reset(): void {
    clearTimeout(this.timerId);
    this.current = this.duration;
  }
}

export default CDown;
