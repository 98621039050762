import axios from '@/utils/axios.ts';

// 获取直播房间信息
export async function getLiveRoomLists(params) {
  return axios({
    url: '/mobi-investor/v1/liveRoom',
    method: 'get',
    params,
  });
}

// 获取跳转至个人版的token

export async function getCloudToken(params) {
  return axios({
    url: '/mobi-investor/v1/login/randomId',
    method: 'get',
    params,
  });
}
