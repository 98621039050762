<template>
  <div>
    <el-form
      ref="loginForm"
      class="login-form"
      :rules="rules"
      :model="formFields"
    >
      <el-form-item
        prop="username"
      >
        <el-input
          v-model="formFields.username"
          placeholder="请输入手机号码"
        />
      </el-form-item>
      <el-form-item
        prop="password"
      >
        <el-input
          v-model="formFields.password"
          :type="pwdVisible ? 'text' : 'password'"
          placeholder="请输入密码"
          class="input-password"
        >
          <template slot="suffix">
            <span @click="pwdVisible = !pwdVisible">
              <i
                v-if="pwdVisible"
                class="toggle-pwd-icon iconfont icon-v_openeyes"
              />
              <i
                v-else
                class="toggle-pwd-icon iconfont icon-v_closeeyes"
              />
            </span>
          </template>
        </el-input>
        <a

          class="forget-pwd"
          @click="handleForget"
        >
          忘记密码？
        </a>
      </el-form-item>
    </el-form>
    <el-button
      class="submit-btn"
      type="primary"
      :loading="submitLoading"
      @click="handleSubmit"
    >
      登录
    </el-button>
    <gee-test
      ref="geeTest"
      :get-params="getGeeTestParams"
      @varify-success="handleVarifySuccess"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import GeeTest from '@/components/GeeTest';
import * as loginService from '@/services/login';

const NEED_TEST_CODE = 4032;

export default {
  components: {
    GeeTest,
  },
  props: {
    /**
     * 两种场景触发不同行为
     */
    scene: {
      type: String,
      default: 'page', // dialog
    },
  },
  data() {
    return {

      submitLoading: false,
      pwdVisible: false,
      formFields: {
        username: '',
        password: '',
      },
      rules: {
        username: [
          { required: true, message: '请输入账号', trigger: 'change' },
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'change' },
        ],
      },
    };
  },

  methods: {
    ...mapActions('login', [
      'login',
      'showLoginDialog',
      'hideLoginDialog',
      'toggleForgetDialogVisible',
    ]),
    handleForget() {
      if (this.scene === 'dialog') {
        this.hideLoginDialog();
        this.$nextTick(() => {
          this.toggleForgetDialogVisible(true);
        });
      }
    },

    async getGeeTestParams() {
      const { data } = await loginService.geeTestInit();
      return data;
    },

    handleSubmit() {
      this.$refs.loginForm.validate(async (valid) => {
        if (valid) {
          this.submitLoading = true;
          try {
            await this.login({
              scene: this.scene,
              type: 'login',
              bind: false,
              needCaptcha: false,
              username: this.formFields.username,
              password: this.formFields.password,
            });
          } catch (error) {
            // 默认错误提示
            // eslint-disable-next-line
            const { response } = error;
            const { data } = response;

            if (NEED_TEST_CODE === data.code) {
              // 需要校验验证码
              this.$refs.geeTest.triggerVerify();
              this.submitLoading = false;
              return;
            }
            this.$message.error(data.message || data.msg);
          }
          this.submitLoading = false;
        } else {
          return false;
        }
      });
    },

    async handleVarifySuccess(geeTestData) {
      try {
        await this.login({
          scene: this.scene,
          type: 'login',
          bind: false,
          needCaptcha: false,
          username: this.formFields.username,
          password: this.formFields.password,
          ...geeTestData,
        });
      } catch (error) {
        // 默认错误提示
        // eslint-disable-next-line
        const { response } = error;
        const { data } = response;
        this.$message.error(data.message || data.msg);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../style.scss';

.login-form {
  padding: 0;

  & .el-form-item {
    margin-top: 32px;
  }

  & .forget-pwd {
    line-height: normal;
    font-size: 14px;
    position: absolute;
    right: 0;
    bottom: -22px;
    cursor: pointer;
  }
}

.submit-btn {
  width: 100%;
  margin-top: 20px;

  /deep/ .el-icon-loading {
    margin-left: -26px;
  }
}
</style>
