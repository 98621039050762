import Vue from 'vue';
import VueRouter from 'vue-router';
import { RouterMap } from './routes.js';
import hooks from './hooks';

Vue.use(VueRouter);
const router = new VueRouter({
  mode: 'hash',
  scrollBehavior: () => ({ y: 0 }),
  routes: RouterMap,
});
/**
 * 全局路由钩子
 */
hooks(router);
// vue-router 容错处理
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => err);
};
export default router;
