import axios from '@/utils/axios.ts';

/**
 * 登出 退出账号中心
 * @see http://192.168.2.9:8080/workspace/myWorkspace.do?projectId=119#805
 * @param return_to {}
 *
 */
export function logout(params) {
  return axios({
    url: '/log/n/api/user/logout',
    method: 'get',
    params,
  });
}

// 下面是live用到的
/**
 * 获取身份信息，设置会话cookie
 * @param account {string} 账号
 */
export function getUserIdentiry() {
  return axios({
    url: '/user/identity',
    method: 'GET',
    withCredentials: true,
  });
}

/**
 * 获取用户信息
 */
export async function getProfile() {
  return axios({
    method: 'get',
    url: '/mobi-investor/user/profile/v2',
  });
}

// 获取用户信息
export async function fetchUserVipInfo() {
  return axios({
    method: 'get',
    url: '/cloud/api/business/userInfo/queryByUserId',
  });
}

// 修改用户名称和头像
export async function editUserInfo(params) {
  return axios({
    method: 'PUT',
    url: '/mobi-investor/user/profile',
    data: {
      ...params,
    },
  });
}

// 获取用户卡片信息
export async function getUserCardInfo(params) {
  return axios({
    method: 'get',
    url: `/mobi-investor/v1/liveRoom/getUserInfoCard/${params}`,
  });
}

// 获取互关用户
export async function getMutualFollowUser(params) {
  return axios({
    method: 'get',
    url: `/mobi-investor/v1/liveRoomUser/getMutualFollowList?liveRoomId=${params.liveRoomId}&page=${params.page}&pageSize=${params.pageSize}`,
  });
}

// 邀请用户
export async function invitedOther(params) {
  return axios({
    method: 'post',
    url: '/mobi-investor/v1/liveRoomUser/invitedOther',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    __interceptorOptions__: { encode: false },
    data: {
      ...params,
    },
  });
}

// 根据姓名查询用户
export async function queryFollowUser(params) {
  return axios({
    method: 'get',
    url: `/mobi-investor/search/findUser?name=${params.name}&page=${params.page}&pageSize=${params.pageSize}`,
  });
}

// 我的Live
export function myLive(params) {
  return axios({
    url: 'mobi-investor/v1/liveRoom/myLive',
    method: 'get',
    params,
  });
}

// 我的Live-开始直播
export function startLive(params) {
  return axios({
    url: 'mobi-investor/v1/liveRoom/startLive',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    __interceptorOptions__: { encode: false },
    data: {
      ...params,
    },
  });
}
