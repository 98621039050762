export const RouterMap = [
  {
    path: '/',
    name: 'app',
    redirect: '/home',
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/home/index.vue'),
  },
  {
    path: '/liveroom/:id',
    name: 'liveroom',
    component: () => import('@/views/liveRoom/index.vue'),
  },
  {
    path: '/replay/:id',
    name: 'replay',
    component: () => import('@/views/replay/index_double.vue'),
  },
  {
    path: '/recordLive/:id',
    name: 'recordLive',
    component: () => import('@/views/recordLive/index.vue'),
  },
  {
    path: '/live/:id',
    name: 'bookRoomDetail',
    component: () => import('@/views/bookRoomDetail/index.vue'),
  },
  {
    path: '/publish',
    name: 'publish',
    component: () => import('@/views/publish/index.vue'),
  },
  {
    path: '/myLive',
    name: 'myLive',
    component: () => import('@/views/myLive/index.vue'),
  },
  {
    path: '/404',
    component: () => import('@/views/404.vue'),
    hidden: true,
  },
  {
    path: '*',
    component: () => import('@/views/404.vue'),
    name: '*',
    hidden: true,
  },
];
