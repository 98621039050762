<template>
  <el-upload
    ref="imgUpload"
    :drag="canDrag"
    :disabled="disabled"
    :action="action"
    :data="uploadFields"
    :show-file-list="showFilelist"
    :on-success="handleSuccess"
    :before-upload="beforeUpload"
  >
    <slot
      :imageUrl="imageUrl"
      :progress="progress"
    />
  </el-upload>
</template>

<script>
import axios from '@/utils/axios';
import config from '@/config';

export default {
  props: {
    canDrag: {
      type: Boolean,
      default: false,
    },
    action: {
      type: String,
      default: config.oss.public.uploadServer,
    },
    validator: {
      type: [Function, RegExp],
      required: true,
    },
    /**
     * 存储桶名
     */
    bucket: {
      // 'krplus-pic'是对应fileType'pic'参数,
      // 'krplus-priv'是对应fileType为'file'参数
      type: String,
      default: config.oss.public.bucket,
    },
    /**
     * 文件类型 pic or file
     */
    fileType: {
      type: String,
      default: 'pic',
    },

    value: {
      type: String,
      default: '',
    },
    disabled: Boolean,
    showFilelist: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      progress: 0,
      imageUrl: '',
      uploadFields: {
        signature: '',
        policy: '',
      },
    };
  },
  created() {
    this.imageUrl = this.value;
  },
  methods: {
    handleSuccess(res, file) {
      if (res.code === 200) {
        this.imageUrl = URL.createObjectURL(file.raw);
        let fileUrl = '';

        const { private: privateConfig, public: publicConfig } = config.oss;

        if (this.bucket === 'krplus-priv') {
          fileUrl = privateConfig.visiteServer + res.url;
        } else {
          fileUrl = publicConfig.visiteServer + res.url;
        }

        const fileName = file.name;
        this.$emit('input', fileUrl, fileName);
      } else {
        this.$message.error(res.msg);
      }
    },
    validate(file) {
      if (typeof this.validator === 'function') {
        return this.validator(file);
      }
      if (this.validator instanceof RegExp) {
        this.$message.error('上传格式不正确');
        return file.type.match(this.validator);
      }
      return true;
    },
    // 上传之前 判断格式 并 请求接口 拿到两个必要的数据
    async beforeUpload(file) {
      // 判断 文件是否符合格式要求
      const isValidate = this.validate(file);
      if (!isValidate) {
        return Promise.reject();
      }
      const data = await this.getSignatureAndPolicy();
      this.uploadFields.signature = data.signature;
      this.uploadFields.policy = data.policy;
    },

    getSignatureAndPolicy() {
      const DAY = 1000 * 60 * 60 * 24;
      return axios({
        url: '/mobi-investor/upload/form-api',
        method: 'post',
        data: {
          param: JSON.stringify({
            bucket: this.bucket, // 存储桶名称
            expiration: parseInt(Date.now() + (DAY * 365), 10),
            'save-key': '/{year}{mon}/{day}{hour}{min}{sec}/{random}{.suffix}',
          }),
          type: this.fileType, // 文件类型 'file' or 'pic'
        },
      });
    },

  },
};
</script>
