import localforage from 'localforage';
import Vue from 'vue';
import Cookies from 'js-cookie';
import { getProfile, getUserCardInfo } from '@/services/user';
import { userIsInvestor, userIsEnterpriser, userIsOrg } from '@/utils/user.js';

const TOKEN_KEY = 'kr_plus_id';
const user = {
  namespaced: true,

  state: {
    isLogin: !!Cookies.get(TOKEN_KEY),
    profile: {},
    cardInfo: {}, // live个人信息
    userInfoDialogVisible: false, // 用户信息编辑弹框展示状态

  },

  mutations: {
    SET_LOGIN: (state, isLogin) => {
      state.isLogin = isLogin;
    },
    SET_PROFILE: (state, profile) => {
      state.profile = profile;
    },

    UPDATE_STATE: (state, payload) => {
      Object.assign(state, payload);
    },

    RESET_STATE: (state) => {
      Object.assign(state, {
        isLogin: !!Cookies.get(TOKEN_KEY),
        profile: {},
        cardInfo: {},
      });
    },

    SET_USERINFO_DIALOG_VISIBLE: (state, payload) => {
      state.userInfoDialogVisible = payload;
    },
  },

  actions: {

    // 获取用户信息
    async getUserInfo({ commit, getters }) {
      try {
        const profile = await getProfile();
        if (profile && profile?.uid) {
          const cardInfoPromise = await getUserCardInfo(profile && profile.uid);
          // 投资人认证中或者是已经是投资人，不弹弹框
          const isInvestor = profile.isInvestorPending || getters.isInvestor;
          const reg = new RegExp('Mr.', 'g');
          if (reg.test(cardInfoPromise?.name) && !isInvestor) {
            commit('SET_USERINFO_DIALOG_VISIBLE', true);
          }
          const cardInfo = {
            ...cardInfoPromise,
            uid: profile.uid,
          };

          commit('UPDATE_STATE', {
            isLogin: true,
            profile,
            cardInfo,
          });
        }
      } catch (error) {
        // console.log(error);
      }
    },
    // 更新用户卡片信息
    async updateCardInfo({ state, commit }) {
      try {
        const { profile } = state;
        if (profile && profile.uid) {
          const cardInfoPromise = await getUserCardInfo(profile && profile.uid);
          const cardInfo = {
            ...cardInfoPromise,
            uid: profile.uid,
          };
          commit('UPDATE_STATE', {
            cardInfo,
          });
        }
        // 如果
      } catch (error) {
        // console.log(error);
      }
    },

    async initUserState({ state }) {
      if (state.isLogin) {
        window.__g_storage__ = localforage.createInstance({
          name: `Jingdata T ${__g_config__.storeVersion}`,
          storeName: `user_${state.profile.uid}`,
          description: '用户本地存储',
        });
        Vue.prototype.$storage = window.__g_storage__;

        await window.__g_storage__.ready();
      }
    },
  },

  getters: {
    // 是否svip
    // isSvip: (state) => state.vipInfo.type === 3,
    // 是否创业者
    isEnterpriser: (state) => (
      state.cardInfo ? userIsEnterpriser(state.cardInfo.enterpriser) : false
    ),

    // 是否投资人
    isInvestor: (state) => (state.cardInfo ? userIsInvestor(state.cardInfo.investorType) : false),

    // 是否是服务机构

    isOrg: (state) => (state.cardInfo ? userIsOrg(state.cardInfo.investorType) : false),

    // 创业者等级
    enterpriserVipLevel: (state) => state.profile.enterpriserVipLevel,

    // 是否已认证
    isCertifacation: (state, getters) => {
      const isEnterpriser = getters.isEnterpriser;
      const isInvestor = getters.isInvestor;
      return isEnterpriser || isInvestor;
    },
    // 角色列表
    roles: (state, getters) => {
      const isEnterpriser = getters.isEnterpriser;
      const isInvestor = getters.isInvestor;
      const isOrg = getters.isOrg;

      const userRoles = [];

      if (isEnterpriser) {
        userRoles.push('enterpriser');
      }

      if (isInvestor) {
        userRoles.push('investor');
      }

      if (isOrg) {
        userRoles.push('isOrg');
      }

      return userRoles;
    },
    getUsetId: (state) => {
      return state.profile.uid;
    },
  },
};

export default user;
