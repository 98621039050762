<template>
  <div class="reset-form">
    <el-form
      ref="forgetForm"
      class="forget-form"
      :rules="rules"
      :model="formFields"
    >
      <el-form-item
        prop="account"
      >
        <el-input
          v-model="formFields.account"
          placeholder="请输入手机号码"
        />
      </el-form-item>
      <el-form-item
        prop="captcha"
        class="form-item-captcha"
      >
        <el-row
          type="flex"
          justify="space-between"
        >
          <el-col class="captcha-col">
            <el-input
              v-model="formFields.captcha"
              placeholder="请输入验证码"
            />
          </el-col>
          <el-col class="btn-col">
            <el-button
              class="send-btn"
              :disabled="sendBtnDisable"
              @click="handleSendCaptcha"
            >
              {{ sendBtnText }}
            </el-button>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item
        prop="password"
      >
        <el-input
          v-model="formFields.password"
          class="input-password"
          :type="pwdVisible ? 'text' : 'password'"
          placeholder="设置新密码（不少于6位）"
        >
          <template slot="suffix">
            <span @click="pwdVisible = !pwdVisible">
              <i
                v-if="pwdVisible"
                class="toggle-pwd-icon iconfont icon-v_openeyes"
              />
              <i
                v-else
                class="toggle-pwd-icon iconfont icon-v_closeeyes"
              />
            </span>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item
        prop="confirmPwd"
        class="form-item-passowrd"
      >
        <el-input
          v-model="formFields.confirmPwd"
          class="input-password"
          :type="confirmPwdVisible ? 'text' : 'password'"
          placeholder="再次确认新密码"
        >
          <template slot="suffix">
            <span @click="confirmPwdVisible = !confirmPwdVisible">
              <i
                v-if="confirmPwdVisible"
                class="toggle-pwd-icon iconfont icon-v_openeyes"
              />
              <i
                v-else
                class="toggle-pwd-icon iconfont icon-v_closeeyes"
              />
            </span>
          </template>
        </el-input>
      </el-form-item>
    </el-form>
    <el-button
      class="submit-btn"
      type="primary"
      :loading="submitLoading"
      @click="handleSubmit"
    >
      重置密码
    </el-button>
    <gee-test
      ref="geeTest"
      :get-params="getGeeTestParams"
      @varify-success="handleVarifySuccess"
    />
  </div>
</template>

<script>
import GeeTest from '@/components/GeeTest';
import CountDown from '@/utils/countDown';
import * as loginService from '@/services/login';

export default {
  components: {
    GeeTest,
  },
  /**
   * 两种场景触发不同行为
   */
  scene: {
    type: String,
    default: 'page', // dialog
  },

  data() {
    return {

      geeTestOptions: {
        product: 'bind',
      },

      step: 'reset',
      submitLoading: false,

      sendBtnDisable: false,
      sendBtnText: '获取验证码',
      isSend: false,
      areaCodeOptions: [],

      pwdVisible: false,
      confirmPwdVisible: false,

      formFields: {
        areaCode: '86',
        account: '',
        captcha: '',

        password: '',
        confirmPwd: '',
      },
      rules: {
        account: [
          { required: true, message: '请输入手机号码', trigger: 'change' },
          // { pattern: /^1\d{10}$/, message: '手机号格式错误', trigger: 'change' },
        ],
        captcha: [
          { required: true, message: '请输入验证码', trigger: 'change' },
        ],
        password: [
          {
            validator: (rule, value, callback) => {
              if (value === '') {
                callback(new Error('请输入密码'));
              } else {
                if (this.formFields.confirmPwd !== '') {
                  this.$refs.forgetForm.validateField('confirmPwd');
                }
                callback();
              }
            },
            trigger: 'change',
          },
        ],
        confirmPwd: [
          {
            validator: (rule, value, callback) => {
              if (value === '') {
                callback(new Error('请再次输入密码'));
              } else if (value !== this.formFields.password) {
                callback(new Error('两次输入密码不一致!'));
              } else {
                callback();
              }
            },
            trigger: 'change',
          },
        ],
      },
    };
  },

  beforeCreate() {
    this.cd = new CountDown({
      duration: 30,
      interval: 1,
      onProgress: (status, current) => {
        // 'start' 'running' or 'end'
        if (status === 'start' || status === 'running') {
          this.sendBtnText = `${current}秒后获取`;
          this.sendBtnDisable = true;
        }

        if (status === 'end') {
          this.sendBtnText = '获取验证码';
          this.sendBtnDisable = false;
        }
      },
    });
  },

  created() {
    this.getAreaCode();
  },

  mounted() {
    // this.initGeeTest();
  },

  methods: {

    async getGeeTestParams() {
      const { data } = await loginService.geeTestInit();
      return data;
    },

    async getAreaCode() {
      const { data } = await loginService.getCC();
      this.areaCodeOptions = data;
    },

    checkUsernameType() {
      if (this.username.indexOf('@') > -1) {
        return 'mail';
      }
      return 'sms';
    },

    handleSendCaptcha() {
      this.$refs.forgetForm.validateField(['account'], async (errorMessage) => {
        if (!errorMessage) {
          this.$refs.geeTest.triggerVerify();
        }
      });
    },

    async handleVarifySuccess(geeTestRes) {
      try {
        let type = 'sms';
        if (!/^\d+$/.test(this.formFields.account)) {
          type = 'mail';
        }
        await loginService.sendResetCode({
          type,
          account: this.formFields.account,
          voice: false,
          ...geeTestRes,
        });
        this.$message.success('验证码发送成功');
        this.cd.start();
        this.isSend = true;
      } catch (e) {
        const { response } = e;
        const { data } = response;
        this.$message.error(data.message);
      }
    },

    async handleSubmit() {
      // if (!this.isSend) {
      //   this.$message.error('请先获取验证码');
      //   return;
      // }
      this.$refs.forgetForm.validate(async (valid) => {
        if (valid) {
          this.submitLoading = true;
          try {
            await loginService.verifyCode({
              code: this.formFields.captcha,
            });

            await loginService.setPassword({
              passwd: this.formFields.password,
            });
            // this.step = 'success';
            this.$emit('reset-success');
            this.$message.success('重置密码成功');
          } catch (error) {
            const { response } = error;
            const { data } = response;
            this.$message.error(data.message || '未知错误');
          }

          this.submitLoading = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../style.scss';

.reset-form {
  width: 300px;
  margin-left: auto;
  margin-right: auto;

  & .forget-form {
    margin-top: 32px;
    margin-bottom: 32px;
    padding: 0;
  }

  & .el-form-item {
    margin-top: 24px;
    position: relative;
  }

  .submit-btn {
    width: 100%;

    /deep/ .el-icon-loading {
      margin-left: -26px;
    }
    // margin-top: 4px;
  }
}
</style>
