import config from '@/config';

const userAgent = '36kr-Tou';

const environmentHref = config.skipLink;

const cloudRequestPath = `${environmentHref}/api/rong/mobi-investor/v1/login/exchangeHost?userAgent=${userAgent}`;
export {
  environmentHref,
  cloudRequestPath,
};
