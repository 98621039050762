<template>
  <el-dialog
    :visible="true"
    :close-on-click-modal="false"
    class="login-dialog"
    append-to-body
    lock-scroll
    width="737px"
    destroy-on-close
    @close="onClose"
  >
    <template v-slot:title>
      <img
        class="header-logo"
        src="@/assets/img/logo-2@2x.png"
        alt=""
      >
    </template>
    <div class="content-wrapper">
      <div class="qr-section">
        <h2 class="qr-title">
          扫码登录
        </h2>
        <scan-qr
          scene="dialog"
        />
        <p class="qr-tip">
          打开 <a>鲸准APP</a>
          <br>
          在首页-扫一扫 扫描二维码登录
        </p>
      </div>
      <div class="form-section">
        <div class="form-content">
          <div class="form-tabs">
            <div
              v-for="tab in tabs"
              :key="tab.value"
              class="tab-item"
              :class="{active: tab.value === loginDialogActiveTab}"
              @click="handleClickTab(tab)"
            >
              {{ tab.label }}
            </div>
          </div>
          <div class="tab-content">
            <sms-form
              v-show="loginDialogActiveTab === 'sms'"
              scene="dialog"
              class="sms-form"
              type="dialog"
            />
            <password-form
              v-show="loginDialogActiveTab === 'password'"
              scene="dialog"
              class="password-form"
              type="dialog"
            />
          </div>
          <div class="form-tip">
            登录即代表您已阅读并同意
            <a @click="toggleServiceDialog(true)">用户服务协议</a>
            及
            <a @click="togglePrivicyDialog(true)">隐私政策</a>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { mapState } from 'vuex';
import ScanQr from '../ScanQr';
import SmsForm from '../SmsForm';
import PasswordForm from '../PasswordForm';

export default {
  components: {
    ScanQr,
    SmsForm,
    PasswordForm,
  },

  data() {
    return {
      // activeTab: 'sms',
      tabs: [
        {
          label: '快速登录',
          value: 'sms',
        },
        {
          label: '账号登录',
          value: 'password',
        },
      ],
    };
  },
  computed: {
    ...mapState('login', [
      'loginDialogActiveTab',
    ]),
  },

  methods: {
    onClose() {
      this.$store.dispatch('login/hideLoginDialog');
    },
    handleClickTab(tab) {
      this.$store.commit('login/SET_LOGIN_DIALOG_ACTIVE', tab.value);
    },

    togglePrivicyDialog(visible) {
      this.$store.commit('login/SET_PRIVICY_DIALOG_VISIBLE', visible);
    },

    toggleServiceDialog(visible) {
      this.$store.commit('login/SET_SERVICE_DIALOG_VISIBLE', visible);
    },
  },
};
</script>

<style lang="scss" scoped>
.login-dialog {
  /deep/ .el-dialog {
    margin-top: calc((100vh - 402px) / 2) !important;
  }

  .header-logo {
    height: 24px;
    vertical-align: middle;
  }

  /deep/ .el-dialog__header {
    // height: 80px;
    border-bottom: 0;

    .el-icon-close {
      font-size: 20px;
    }
  }

  /deep/ .el-dialog__body {
    padding: 0;
    height: calc(402px - 48px);
    max-height: calc(402px - 48px);
  }

  .content-wrapper {
    display: flex;
    margin-top: 12px;
    height: 282px;

    .qr-section {
      flex-shrink: 0;
      width: 296px;
      // border-right: 1px;
      background-size: 1px 100%;
      background-position: 100% 0;
      background-repeat: no-repeat;
      background-image: linear-gradient(180deg, #fff 0%, #e4e5eb 36%, #e4e5eb 68%, #fff 100%);
      display: flex;
      // align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;

      .qr-title {
        font-size: 18px;
        color: #333b44;
        font-weight: bold;
        line-height: 26px;
      }

      .qr-tip {
        font-size: 14px;
        color: #333b44;
        line-height: 20px;
      }
    }

    /deep/ .qrcode-login {
      padding: 0;
      margin-top: 30px;
      margin-bottom: 30px;

      .qrcode-wrapper {
        height: 156px;
        width: 156px;
      }
    }

    .form-section {
      flex-grow: 1;
      display: flex;
      justify-content: center;

      .form-content {
        width: 300px;
      }

      .form-tabs {
        display: flex;
        justify-content: center;
        line-height: 26px;

        .tab-item {
          font-size: 18px;
          color: #333b44;
          font-weight: bold;
          padding-bottom: 4px;
          cursor: pointer;

          + .tab-item {
            margin-left: 56px;
          }

          &.active {
            border-bottom: 2px solid #3e74ca;
            color: #3e74ca;
          }
        }
      }

      /deep/ .el-form {
        width: auto;
      }

      .tab-content {
        /deep/ .el-form-item {
          margin-top: 24px;
        }

        /deep/ .submit-btn {
          margin-top: 12px;
        }
      }

      .form-tip {
        margin-top: 32px;
        font-size: 12px;
        color: #959aa2;

        a {
          color: #3e74ca;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
