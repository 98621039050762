import Vue from 'vue';
import ElementUI from 'element-ui';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';

import App from './App.vue';
// import ElementUI from 'element-ui'
import './class-component-hooks';

import 'element-ui/lib/theme-chalk/index.css';

import '@/assets/iconfont/iconfont.css';

import '@/directive';
import router from './router';
import store from './store';
import config from './config';
import './assets/style/index.scss';

Vue.prototype.$config = config;

Vue.config.productionTip = false;
Vue.use(ElementUI);

if (config.env === 'production') {
  Sentry.init({
    Vue,
    dsn: 'https://13be879d9b81486abc9e9fd2ae11f256@sentry.jingdata.com/7',
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['localhost', 'https://live.capitalcoffee.club/', /^\//],
      }),
    ],
    release: process.env.SENTRY_RELEASE,
    logErrors: true,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

window.__g_store__ = store;
window.__g_router__ = router;

new Vue({
  render: (h) => h(App),
  router,
  store,
}).$mount('#app');
