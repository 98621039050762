import axios from '@/utils/axios.ts';

// 获取直播房间信息
export async function getLiveRoom(roomId) {
  return axios({
    url: `/mobi-investor/v1/liveRoom/${roomId}`,
    method: 'get',
  });
}

// 获取音频rtc token
export async function getRtcToken(params) {
  return axios({
    url: '/mobi-investor/v1/liveConfig/rtcToken',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    __interceptorOptions__: { encode: false },
    data: {
      ...params,
    },
  });
}

// 获取白板room token
export async function getRoomToken(params) {
  return axios({
    url: '/mobi-investor/v1/liveConfig/roomToken',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    __interceptorOptions__: { encode: false },
    data: {
      ...params,
    },
  });
}

// 获取云信令rtm token
export async function getRtmToken() {
  return axios({
    url: '/mobi-investor/v1/liveConfig/rtmToken',
    method: 'post',
  });
}

// 获取项目集列表
export async function getProjectAlbum(roomId) {
  return axios({
    url: `/mobi-investor/v1/liveProject/list/${roomId}`,
    method: 'get',
  });
}

// 获取项目集ppt信息
export async function getProjectPptInfo(params) {
  return axios({
    url: '/mobi-investor/v1/liveProject/resource/getResouces',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    __interceptorOptions__: { encode: false },
    data: {
      ...params,
    },
  });
}
// 预约直播
export async function subscribeLive(params) {
  return axios({
    url: '/mobi-investor/v1/liveRoom/subscribe',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    __interceptorOptions__: {
      encode: false,
      hideMsg: true,
      showResult: true,
    },
    data: {
      ...params,
    },
  });
}

// 申请直播
export async function applyLive(params) {
  return axios({
    url: '/mobi-investor/v1/liveRoom/apply',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    __interceptorOptions__: {
      encode: false,
      hideMsg: true,
      showResult: true,
    },
    data: {
      ...params,
    },
  });
}

// 加入/离开房间
export async function joinLeaveRoom(params) {
  return axios({
    url: '/mobi-investor/v1/liveRoom/join',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    __interceptorOptions__: { encode: false },
    data: {
      ...params,
    },
  });
}

/*
* 举手
* liveRoomId   房间Id
* type  类型 1举手
*/
export async function setHandsUp(params) {
  return axios({
    url: '/mobi-investor/v1/liveRoomUserApply/save',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    __interceptorOptions__: { encode: false },
    data: {
      ...params,
    },
  });
}

/*
* 应答举手
* liveRoomId   房间Id
* status 类型  申请状态（0[默认]待审核｜1同意｜2拒绝）
* createId  举手用户id
*/
export async function setHandsResponse(params) {
  return axios({
    url: '/mobi-investor/v1/liveRoomUserApply/handResponse',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    __interceptorOptions__: { encode: false },
    data: {
      ...params,
    },
  });
}

/*
* 开闭麦
* liveRoomId   房间Id
* speech   是否开麦 0否｜1是
*/
export async function setMicrophone(params) {
  return axios({
    url: '/mobi-investor/v1/liveRoomUser/updateSpeech',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    __interceptorOptions__: { encode: false },
    data: {
      ...params,
    },
  });
}

// 修改举手开关状态
export async function setHandsType(params) {
  return axios({
    url: '/mobi-investor/v1/liveRoom/updateHandsType',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    __interceptorOptions__: { encode: false },
    data: {
      ...params,
    },
  });
}

// 获取直播房间举手类型
export async function getHandsType(roomId) {
  return axios({
    url: `/mobi-investor/v1/liveRoom/getHandsType/${roomId}`,
    method: 'get',
  });
}

// 获取举手申请列表
export async function getApplyList(params) {
  return axios({
    url: '/mobi-investor/v1/liveRoomUser/getApplyList',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    __interceptorOptions__: { encode: false },
    data: {
      ...params,
    },
  });
}

// 举报
export async function reportRoom(params) {
  return axios({
    url: '/mobi-investor/v1/LiveRoomReport/save',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    __interceptorOptions__: { encode: false },
    data: {
      ...params,
    },
  });
}
// 获取举报类型合集
export async function getReportType() {
  return axios({
    url: '/mobi-investor/v1/LiveRoomReport/queryReportType',
    method: 'get',
  });
}

/**
 * 设置直播间用户角色
 * liveRoomId   房间Id
 * uid   用户id
 * liveUserType  房间用户身份（1嘉宾｜2普通用户）
*/
export async function setUserRole(params) {
  return axios({
    url: '/mobi-investor/v1/liveRoomUser/updateUserType',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    __interceptorOptions__: { encode: false },
    data: {
      ...params,
    },
  });
}

/**
 * 设置直播间用户操作白板权限
 * liveRoomId   房间Id
 * uid   用户id
 * roomAction  0无权限，1操作ppt
*/
export async function setWhiteRole(params) {
  return axios({
    url: '/mobi-investor/v1/liveRoomUser/updateUserAction',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    __interceptorOptions__: { encode: false },
    data: {
      ...params,
    },
  });
}

/**
 * 点赞
*/
export async function setThumbCount(params) {
  return axios({
    url: `/mobi-investor//v1/liveRoom/increaseNumber/${params.roomId}/${params.number}`,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    __interceptorOptions__: { encode: false },
  });
}

// 关闭直播
export async function endLive(params) {
  return axios({
    url: '/mobi-investor/v1/liveRoom/endLive',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    __interceptorOptions__: { encode: false },
    data: {
      ...params,
    },
  });
}
// 关注 取关
export async function setFollow(params) {
  return axios({
    url: '/mobi-investor/v1/liveRoomUser/saveFollow',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    __interceptorOptions__: { encode: false },
    data: {
      ...params,
    },
  });
}

// 管理员踢人
export async function setKickOut(params) {
  return axios({
    url: '/mobi-investor/v1/liveRoomUser/user/kickOut',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    __interceptorOptions__: { encode: false },
    data: {
      ...params,
    },
  });
}

// 录制
export async function startRecord(params) {
  return axios({
    url: '/mobi-investor/trans/room/startRecord/webPage',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    __interceptorOptions__: { encode: false },
    data: {
      ...params,
    },
  });
}

// 停止录制
export async function stopRecord(params) {
  return axios({
    url: '/mobi-investor/trans/room/stopRecord/webPage',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    __interceptorOptions__: { encode: false },
    data: {
      ...params,
    },
  });
}

// 申请列表
export async function getApplicationList(roomId) {
  return axios({
    url: `/mobi-investor/v1/liveRoomUser/getApplyList?liveRoomId=${roomId}&page=1&pageSize=100`,
    method: 'get',
  });
}

// 录制
export async function setAgreeApply(params) {
  return axios({
    url: '/mobi-investor/v1/liveRoomUser/agreeApply',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    __interceptorOptions__: { encode: false },
    data: {
      ...params,
    },
  });
}

// 录制
export async function setAgreeApplyAll(roomId) {
  return axios({
    url: `/mobi-investor/v1/liveRoomUser/agreeApplyAll?liveRoomId=${roomId}`,
    method: 'get',
  });
}

// 获取直播房间基本信息
export async function getRoomInfo(roomId) {
  return axios({
    url: `/mobi-investor/v2/liveRoom/info?liveRoomId=${roomId}`,
    method: 'get',
  });
}

// 获取直播房间用户列表
export async function getRoomUserList(roomId) {
  return axios({
    url: `/mobi-investor/v2/liveRoom/userInfo?liveRoomId=${roomId}`,
    method: 'get',
  });
}
