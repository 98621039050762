// 是否是创业者  enterpriser === 2
export function userIsEnterpriser(identityRoles = null) {
  if (!identityRoles) return false;
  return identityRoles === 2;
}
// 非投资人，investorType为100

// 投资人 investorType < 100
export function userIsInvestor(identityRoles = null) {
  if (!identityRoles) return false;
  return identityRoles < 100;
}

// 服务机构 investorType > 100
export function userIsOrg(identityRoles = null) {
  if (!identityRoles) return false;
  return identityRoles > 100;
}
