<!-- eslint-disable max-len -->
<template>
  <el-dialog
    :visible="visible"
    :show-close="false"
    close-on-click-modal
    close-on-press-escape
    custom-class="agreement-dialog"
    @close="$emit('update:visible', false)"
  >
    <h1 class="title">
      隐私政策
    </h1>

    <div class="content">
      <p>本App应用（下称“本平台”）尊重并保护您的个人隐私权。为了给您提供更准确、更有个性化的服务，本平台会按照本隐私政策的规定使用和披露您的个人信息。但本平台将以高度的勤勉、审慎义务对待这些信息。除本隐私政策另有规定外，在未征得您事先许可的情况下，本平台不会将您的个人信息对外披露或向第三方提供。您在使用本平台服务之时，即视为您已经同意本隐私政策全部内容。本平台希望通过隐私政策向您清楚地介绍本平台对您信息，特别是您个人信息的处理方式，因此请您完整地阅读隐私政策，以帮助您更好地保护您的隐私。</p>

      <p>如果您未登录本平台账号，我们会通过获取设备对应的标识符信息来保障信息推送的基本功能。</p>

      <h2>一、我们如何收集和使用您的个人信息及权限获取</h2>
      <p>1: 个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别自然人个人身份的各种信息，包括但不限于自然人的姓名、出生日期、身份证件号码、个人生物识别信息、住址、电话号码等。</p>

      <p>2: 权限获取是为了让您正常体验本平台功能，且权限可通过设备设置进行开启与关闭。您同意平台仅会出于本协议所述之目的，收集和使用您的下列个人信息及权限：</p>

      <p>（1） 您向本平台提供的信息。当您在本平台创建账号时，您将向本平台提供您姓名、电话号码及/或电子邮箱等信息，并创建密码；</p>

      <p>（2） 硬件型号、系统类型、系统版本、分辨率、网络状态类型、唯一识别码（IMEI/android ID/IDFA/IMSI卡）等描述个人终端设备基本情况的信息；</p>

      <p>（3） 您使用本平台服务时所储存的信息。您访问和使用站点和应用程序的信息，包括您所查看的页面、点击的链接、下载的文件、使用的搜索标准以及与站点和应用程序相关的其他操作，以及您的浏览器 或移动设备发送到您访问的每个网站的特定信息，例如您的IP地址、访问时间等；</p>

      <p>（4） 来自第三方的信息。其他方使用本平台时所提供有关您的共享信息；</p>

      <p>（5） 您在使用本平台直播功能时，根据法律法规、安全保障要求，您需要进行身份认证，认证时您需要提供手机号、真实姓名、项目信息。这些信息未经您明示授权，不会用作其他目的；</p>

      <p>（6） 当您使用直播、活动等提醒或预约功能时，我们会获取您的设备日历权限。在您开启相关权限后，我们会访问你的日历，帮助您预约直播、活动的时间，并提醒您；</p>

      <p>（7） 我们可能会请求获取您的短信权限，以便于获取验证码及发送短信，短信属于个人敏感信息，拒绝提供该信息不影响您正常使用本平台功能；</p>

      <p>（8） 其他基于安全、您体验优化等考虑而需收集的信息及权限。</p>

      <p>3: 本平台对您信息的收集将遵循相关法律的规定。您了解并同意，以下信息不适用本隐私政策：</p>

      <p>（1） 您在使用本平台提供的搜索服务时输入的关键字信息；</p>

      <p>（2） 违反法律规定或违反本平台规则行为及本平台已对您采取的措施；</p>

      <p>（3） 应法律法规要求需披露的您信息；</p>

      <p>（4） 紧急情况下，为维护您及公众的利益需披露的您信息；</p>

      <p>（5） 您已自行在网络上公开的信息或者其他已合法公开的信息；</p>

      <p>（6） 为服务您的目的，本应用可能通过使用您的个人信息，向您提供您感兴趣的信息，包括但不限于向您发出产品和服务信息， 或者与本应用合作伙伴共享信息以便他们向您发送有关其产品和服务的信息（后者需要您的事先同意）。</p>

      <p>4: 本平台可能将在向您提供服务的过程之中所收集的信息及权限用作下列用途：</p>

      <p>（1） 向您提供服务；</p>

      <p>（2） 在本平台提供服务时，用于身份验证、客户服务、安全防范、诈骗监测、存档和备份用途，确保本平台向您提供的产品和服务的安全性；</p>

      <p>（3） 帮助本平台设计新服务，改善本平台现有服务；</p>

      <p>（4） 使本平台更加了解您如何接入和使用服务，从而针对性地回应您的个性化需求；</p>

      <p>（5） 系统升级；</p>

      <p>（6） 经您许可的其他用途。</p>

      <h2>二、我们如何存储您的个人信息</h2>

      <p>1: 我们将从中华人民共和国境内获得的信息存放于中华人民共和国境内。如果发生数据的跨境传输，我们会单独向您以弹窗或邮件的方式告知您数据出境的目的、接收方等，并征得您的授权同意，我们会确保数据接收方有充足的数据保护能力来保护您的个人信息。我们承诺仅在本政策所述目的所必需期间和法律法规及监管规定的时限内保存您的个人信息，您个人信息的存储时间始终处于合理必要期限内。</p>

      <p>2: 在您终止使用本平台服务后，我们会停止对您的信息的手机和使用，法律法规或监管部门另有规定的除外。</p>

      <h2>三、我们如何使用Cookie</h2>

      <p>在您未拒绝接受cookies的情况下，本平台会在您的计算机上和/或移动设备设定或取用cookies，以便您能登录或使用依赖于cookies的本平台服务或功能。本平台使用cookies可为您提供更加周到的个性化服务，包括推广服务。通过本应用所设cookies所取得的有关信息，将适用本协议。</p>

      <h2>四、我们如何使用第三方应用工具包</h2>

      <p>为了能够更高效地为您提供更为多元化的服务，本平台采用了第三方提供的软件开发工具包。这种工具包通常称为 SDK（Software Development Kit），是指用于为特定的软件包、软件框架、硬件平台、操作系统等创建应用软件的开发工具的集合。本平台使用的 SDK 包括：</p>

      <p>(1) 微信</p>
      <p>SDK用途：分享内容到第三方平台</p>
      <p>SDK收集的个人信息类型：设备信息</p>
      <p>(2) 支付宝</p>
      <p>SDK用途：购买鲸币来支付服务费用</p>
      <p>SDK收集的个人信息类型：设备信息</p>
      <p>(3) 极验</p>
      <p>SDK用途：防止恶意骚扰、一键登录集成</p>
      <p>SDK收集的个人信息类型：设备信息、手机号码</p>
      <p>权限及应用说明：</p>
      <p>1. 获取网络状态权限，获取网络状态提醒</p>
      <p>2. 存储空间读写权限，存储错误日志信息</p>
      <p>(4) 个推</p>
      <p>SDK用途：推送私信通知、审核结果及推荐内容</p>
      <p>SDK收集的个人信息类型：设备信息</p>
      <p>(5) 神策</p>
      <p>SDK用途：数据统计分析</p>
      <p>SDK收集的个人信息类型：设备信息</p>
      <p>(6) bugly</p>
      <p>SDK用途：崩溃信息收集</p>
      <p>SDK收集的个人信息类型：设备信息</p>
      <p>权限及应用说明：</p>
      <p> 1. 获取网络状态权限，获取网络状态提醒</p>
      <p> 2. 获取手机信息权限，获取手机通话状态</p>
      <p> 3. 读取系统日志权限，上报应用崩溃信息</p>
      <p>(7) ping++</p>
      <p>SDK用途：聚合支付集成</p>
      <p>SDK收集的个人信息类型：设备信息</p>
      <p>权限及应用说明：</p>
      <p> 1. 获取网络状态权限，获取网络状态提醒</p>
      <p> 2. 获取手机信息权限，获取手机通话状态</p>
      <p> 3. 存储空间读写权限，存储错误日志信息</p>
      <p>(8) LinkedMe</p>
      <p>SDK用途：微信打开应用集成</p>
      <p>SDK收集的个人信息类型：设备信息</p>
      <p>权限及应用说明：</p>
      <p> 1. 获取网络状态权限，获取网络状态提醒</p>
      <p> 2. 获取手机信息权限，获取手机的IMEI号</p>
      <p> 3. 存储空间读写权限，存储错误日志信息</p>
      <p>(9) udesk</p>
      <p>SDK用途：客服功能集成</p>
      <p>SDK收集的个人信息类型：设备信息</p>
      <p>*设备信息：硬件型号、系统类型、系统版本、分辨率、网络状态类型、唯一识别码（IMEI/android ID/IDFA/IMSI卡）等描述个人终端设备基本情况的信息</p>

      <h2>五、我们如何共享、转让、公开披露您的个人信息及例外情形</h2>

      <h3>(一) 共享</h3>
      <p>1、 本平台将以合理和谨慎的方式保存、使用、传递和存放您上传的文件及其他信息，在下列情形下本平台可使用、许可任何第三方或不特定对象使用：</p>
      <p>（1） 事先获得授权。获得您的授权后，本平台会与其他公司、组织和个人分享您的信息。只有您选择同意，本平台才会共享您的个人信息；</p>
      <p>（2） 向本平台运营方关联公司分享您的信息。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。 本平台运营方关联公司无权将共享的个人信息用于任何其他用途；</p>
      <p>（3） 用于外部数据处理。向可信赖的合作伙伴提供您的信息，让他们根据本平台的指示并遵循本平台的隐私政策以及其他任何相应的保密和安全措施来为本平台处理这些信息；</p>
      <p>（4） 出于法律原因。为遵守适用的法律法规、法律程序、诉讼或者行政机关、司法机构依法提出的要求，本平台可能有必要披露您的信息；</p>
      <p>（5） 如果确定您出现违反中国有关法律法规或者本平台相关协议或相关规则的情况，或者为保护本平台及其关联公司、您或者公众的合法权益、权利、财产或安全免遭损害，本平台也可能披露关于您的信息；</p>
      <p>（6） 协助处理争议。只有共享您的信息，才能提供您需要的服务，或处理您与他人的纠纷或争议。如您不同意本平台按照上述条款向第三方提供 您的信息，您应立即停止使用本平台并注销账户并且联系本平台客服提出删除个人信息的请求。</p>

      <h3>(二) 转让</h3>

      <p>本平台不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：</p>
      <p>1、 在获取明确同意的情况下转让：获得您的明确同意后，本平台会向第三方转让您的个人信息；</p>
      <p>2、 在涉及公司合并、收购或破产清算时，如涉及到个人信息转让，本平台会在要求新的持有您个人信息的公司、组织继续受此隐私政策的约束，否则本平台将要求该公司、组织重新向您征求授权同意。</p>

      <h3>(三) 公开披露</h3>
      <p>本平台会在以下情况下，公开披露您的个人信息：</p>
      <p>1、 获得您明确同意后；</p>
      <p>2、 基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，可能会公开披露您的个人信息。</p>

      <h3>(四) 例外情形</h3>
      <p>根据相关法律法规的规定，在以下情形中，我们可以在不征得您的授权同意的情况下共享、转让、公开披露您的个人信息：</p>
      <p>（1） 与国家安全、国防安全有关的；</p>
      <p>（2） 与公共安全、公共卫生、重大公共利益有关的；</p>
      <p>（3） 与犯罪侦查、起诉、审判和判决执行等有关的；</p>
      <p>（4） 出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</p>
      <p>（5） 您自行向社会公众公开的个人信息；</p>
      <p>（6） 从合法公开披露的信息中收集到的个人信息的，如合法的新闻报道、政府信息公开等渠道；</p>
      <p>（7） 法律法规规定的其他情形；</p>
      <p>（8） 您充分理解并同意，共享、转让经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。</p>

      <h2>六、 您的权利</h2>

      <h3>(一) 个人信息访问</h3>
      <p>您有权访问您的个人信息，法律法规规定的例外情况除外。</p>

      <h3>(二) 个人信息的更改</h3>

      <p>当您发现本平台处理的关于您的个人信息有错误时，您可以通过“我的”-“我的资料”进行修改和编辑，或通过“我的”-“官方客服”提出更正或补充申请。</p>

      <h3>(三) 个人信息的删除</h3>

      <p>1、 在以下情形中，您可以向本平台提出删除个人信息的请求：</p>

      <p>（1） 如果本平台处理个人信息的行为违反法律法规；</p>
      <p>（2） 如果本平台收集、使用您的个人信息，却未征得您的明确同意；</p>
      <p>（3） 如果本平台处理个人信息的行为严重违反了与您的约定；</p>
      <p>（4） 如果本平台停止运营或永久不再为您提供产品或服务后，本平台将从备份系统中删除您信息。</p>

      <p>2、 当您从本平台的服务中删除信息后，本平台可能不会立即从备份系统中删除相应的信息，但会在备份更新时删除这些信息。</p>

      <h2>七、 我们如何保护您的个人信息</h2>

      <h3>(一) 技术措施与数据安全措施</h3>
      <p>1、我们努力采取各种符合业界标准的物理、电子和管理方面的安全措施来保护您的个人信息安全。我们积极建立数据分类分级制度、数据安全管理规范、数据安全开发规范来管理规范个人信息的存储和使用，确保未收集与我们提供的服务无关的个人信息。</p>
      <p>2、我们已使用符合业界标准的安全防护措施保护您提供的个人信息，防止数据遭到未经授权的访问、公开披露、使用、修改，防止数据发生损坏或丢失。我们会采取一切合理可行的措施，保护您的个人信息。我们会使用加密技术确保数据的保密性；我们会使用受信赖的保护机制防止数据遭到恶意攻击；我们会部署访问控制机制，确保只有授权人员才可以访问个人信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。</p>

      <h3>(二) 安全认证</h3>
      <p>我们已通过了公安部信息安全等级保护三级认证，并与监管机构、第三方测评机构建立了良好的协调沟通机制，及时抵御并处置各类信息安全威胁，为您的信息安全提供全方位保障。</p>

      <h3>(三) 安全事件处置</h3>

      <p>1、 我们将尽力确保您发送给我们的任何信息的安全性，但请您理解，由于技术的限制以及在互联网行业存在的各种恶意手段，不始终保证信息百分之百的安全。您需要了解，您接入我们服务所用的系统和通讯网络，有因我们可控范围外的因素而出现问题。为防止安全事故的发生，我们制定了妥善的预警机制和应急预案。若不幸发生个人信息安全事件，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议和对您的补救措施，并立即启动应急预案，力求将损失最小化。我们将及时将事件相关情况以电话、推送通知等方式告知您，难以逐一告知用户时，我们会采取合理、有效的方式发布公告。</p>
      <p>2、 同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况，紧密配合政府机关的工作。</p>

      <h2>八、 隐私政策更新及适用</h2>
      <h3>(一) 本平台可能适时修订本隐私政策的条款，该等修订构成本隐私政策的一部分。未经您明确同意，我们不会削减您按照本隐私政策所应享有的权利。我们会在本平台页面上发布对本隐私政策所做的任何变更。 对于重大变更，本平台将在修订生效前通过在主页上显著位置提示或向您发送电子邮件或以其他方式通知您。本政策所指的重大变更包括但不限于：</h3>
      <p>1、 我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；</p>
      <p>2、 我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；</p>
      <p>3、 个人信息共享、转让或公开披露的主要对象发生变化；</p>
      <p>4、 您参与个人信息处理方面的权利及其行使方式发生重大变化；</p>
      <p>5、 我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；</p>
      <p>6、 个人信息安全影响评估报告表明存在高风险时。</p>

      <p>(二) 本隐私政策适用于本平台提供的所有服务，您访问本平台及未设独立隐私政策的本平台、服务器、及/或登陆相关客户端使用本平台提供的服务，均适用本隐私政策。需要特别说明的是，本隐私政策仅适用于本平台所收集的信息，并不适用于任何第三方提供的服务或第三方的信息使用规则，本平台对任何第三方使用由您提供的信息不承担任何责任。您使用本平台接入第三方网站服务时，须受该第三方的服务条款及隐私政策（而非本隐私政策）约束，您需要仔细阅读其条款。</p>
      <p>(三) 本平台只会在达成本隐私政策所述目的所需的期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。</p>

      <h2>九、 如何联系我们</h2>
      <p>如您对本隐私政策有任何疑问或对您信息保护有任何投诉，您可以通过本平台公布的联系方式与本平台联系。 如果您对本隐私政策有任何疑问、意见或建议，通过以下方式与我们联系：</p>
      <p>公司名称：北京鲸准数服信息科技有限责任公司</p>
      <p>联系方式：邮箱：service@jingdata.com</p>
      <p>本次《隐私政策》更新生效日期：2021年7月5日</p>
    </div>
    <div class="btn-wrapper">
      <el-button
        class="close-btn"
        type="primary"
        @click="$emit('update:visible', false)"
      >
        关闭
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../agreement.scss';
</style>
