import Qs from 'qs';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Message, Loading } from 'element-ui';
import { ElLoadingComponent } from 'element-ui/types/loading';

const BIZ_ERROR = 'BizError';

const instance = axios.create({
  baseURL: '/api',
  timeout: 30000,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
});

let loadingInstance: ElLoadingComponent;

instance.interceptors.request.use((options: AxiosRequestConfig) => {
  const { __interceptorOptions__ } = options;
  if (__interceptorOptions__ && __interceptorOptions__.loading) {
    loadingInstance = Loading.service({
      fullscreen: true,
    });
  }

  if (!(__interceptorOptions__?.encode === false)) {
    if (options.data) {
      options.data = Qs.stringify(options.data);
    }
  }
  return options;
});

function outputError(error: AxiosError) {
  const { response } : { response?: AxiosResponse } = error;
  // eslint-disable-next-line no-console
  console.error(
    '%s\n url: %s\n method: %s\n params: %s\n response: %s',
    error.message,
    error.config.url,
    error.config.method,
    JSON.stringify(error.config.data || error.config.params || null),
    response?.data ? JSON.stringify(response.data) : null,
  );
}
instance.interceptors.response.use((response) => {
  if (loadingInstance) {
    loadingInstance.close();
  }

  const result = response.data;
  const { __interceptorOptions__ } = response.config;

  const data = result.data || result.result;
  const message = result.msg || result.message;
  // 符合约定接口格式，如果非此格式直接返回结果数据
  if (result.code == null || !message) {
    return result;
  }

  if (result.code === 0) {
    return data;
  }
  if ((result?.code) === 403 || (result?.code) === 4032) {
    Message.error({
      message: data.message || '登录信息失效，请重新登录',
      customClass: 'messager',
    });

    (window as any).__g_store__.dispatch('login/clearUserState');
    (window as any).__g_store__.dispatch('login/hideLoginDialog');
    (window as any).__g_store__.dispatch('user/SET_LOGIN', false, {
      root: true,
    });
    // todo: 为啥要加这个逻辑
    // const { currentRoute } = (window as any).__g_router__;
    // if (currentRoute.path !== '/home') {
    //   __g_router__.push({
    //     path: '/home',
    //   });
    // }
    return;
  }

  const error = new Error(`Request failed with biz status code ${result.code}${result.msg ? `, ${result.msg}` : ''}`);
  error.name = BIZ_ERROR;
  error.response = response;
  error.config = response.config;

  if (process.env.NODE_ENV === 'development') {
    outputError(error as AxiosError);
  }
  if (__interceptorOptions__?.hideMsg !== true) {
    Message.error({
      message: message || '未知错误',
      customClass: 'messager',
    });
  }
  if (__interceptorOptions__?.showResult === true) {
    return result;
  }
  return Promise.reject(error);
}, (error) => {
  if (process.env.NODE_ENV === 'development') {
    outputError(error);
  }
  if (loadingInstance) {
    loadingInstance.close();
  }
  const { data } = error?.response;
  Message.error({
    message: data?.message || '后台出错，请联系管理员！',
    customClass: 'messager',
  });
  return Promise.reject(error);
});

export default instance;
