<template>
  <div>
    <el-form
      ref="loginForm"
      class="login-form"
      :rules="rules"
      :model="formFields"
    >
      <el-form-item
        prop="phone"
        class="form-item-phone"
      >
        <el-input
          v-model="formFields.phone"
          class="input-phone"
          maxlength="11"
          placeholder="请输入手机号码"
        >
          <template slot="prefix">
            <el-select v-model="formFields.areaCode">
              <el-option
                v-for="(option, index) in areaCodeOptions"
                :key="index"
                :value="option.cc"
                :label="`+${option.cc}`"
              >
                <div class="area-code-option">
                  <span>{{ option.desc }}</span>
                  <span>+{{ option.cc }}</span>
                </div>
              </el-option>
            </el-select>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item
        prop="captcha"
        class="form-item-captcha"
      >
        <el-row
          type="flex"
          justify="space-between"
        >
          <el-col class="captcha-col">
            <el-input
              v-model="formFields.captcha"
              placeholder="请输入验证码"
            />
          </el-col>
          <el-col class="btn-col">
            <el-button
              class="send-btn"
              :disabled="sendBtnDisable"
              @click="handleSendCaptcha"
            >
              {{ sendBtnText }}
            </el-button>
          </el-col>
        </el-row>
      </el-form-item>
    </el-form>
    <el-button
      class="submit-btn"
      type="primary"
      :loading="submitLoading"
      @click="handleSubmit"
    >
      登录
    </el-button>
    <gee-test
      ref="geeTest"
      :get-params="getGeeTestParams"
      @varify-success="handleVarifySuccess"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import GeeTest from '@/components/GeeTest';
import CountDown from '@/utils/countDown';
import * as loginService from '@/services/login';

const NEED_TEST_CODE = 4032;

export default {
  components: {
    GeeTest,
  },
  props: {
    /**
     * 两种场景触发不同行为
     */
    scene: {
      type: String,
      default: 'page', // dialog
    },
  },

  data() {
    return {
      verifyType: 'sms', // login
      sendBtnDisable: false,
      sendBtnText: '获取验证码',
      isSend: false,
      submitLoading: false,
      areaCodeOptions: [],
      formFields: {
        areaCode: '86',
        phone: '',
        captcha: '',
      },
      rules: {
        phone: [
          { required: true, message: '请输入手机号', trigger: 'change' },
        ],
        captcha: [
          { required: true, message: '请输入验证码', trigger: 'change' },
        ],
      },
    };
  },

  beforeCreate() {
    this.cd = new CountDown({
      duration: 30,
      interval: 1,
      onProgress: (status, current) => {
        // 'start' 'running' or 'end'
        if (status === 'start' || status === 'running') {
          this.sendBtnText = `${current}秒后获取`;
          this.sendBtnDisable = true;
        }

        if (status === 'end') {
          this.sendBtnText = '获取验证码';
          this.sendBtnDisable = false;
        }
      },
    });
  },

  created() {
    this.getAreaCode();
  },

  methods: {
    ...mapActions('login', [
      'login',
    ]),

    async getGeeTestParams() {
      const { data } = await loginService.geeTestInit();
      return data;
    },
    async getAreaCode() {
      const { data } = await loginService.getCC();
      this.areaCodeOptions = data;
    },

    handleSendCaptcha() {
      this.verifyType = 'sms';
      this.$refs.loginForm.validateField(['phone'], async (errorMessage) => {
        if (!errorMessage) {
          this.$refs.geeTest.triggerVerify();
        }
      });
    },

    handleVarifySuccess(geeTestRes) {
      if (this.verifyType === 'sms') {
        this.handleVarifySmsSuccess(geeTestRes);
      } else {
        this.handleVarifyLoginSuccess(geeTestRes);
      }
    },

    async handleVarifySmsSuccess(geeTestRes) {
      try {
        await loginService.sendCode({
          account: `${this.formFields.areaCode}+${this.formFields.phone}`,
          voice: false,
          ...geeTestRes,
        });
        this.$message.success('验证码发送成功');
        this.cd.start();
        this.isSend = true;
      } catch (e) {
        const { response } = e;
        const { data } = response;
        this.$message.error(data.message);
      }
    },

    async handleSubmit() {
      // if (!this.isSend) {
      //   this.$message.error('请先获取验证码');
      //   return;
      // }
      this.verifyType = 'login';
      this.$refs.loginForm.validate(async (valid) => {
        if (valid) {
          this.submitLoading = true;
          try {
            await this.login({
              // type: 'login',
              scene: this.scene,
              bind: false,
              needCaptcha: true,
              username: `${this.formFields.areaCode}+${this.formFields.phone}`,
              verifyCode: this.formFields.captcha,
            });
          } catch (error) {
            const { response } = error;
            const { data } = response;

            if (NEED_TEST_CODE === data.code) {
              // 需要校验验证码
              this.$refs.geeTest.triggerVerify();
              this.submitLoading = false;
              return;
            }
            this.$message.error(response?.data?.message || '未知错误');
          }

          this.submitLoading = false;
        }
      });
    },

    async handleVarifyLoginSuccess(geeTestData) {
      try {
        await this.login({
          scene: this.scene,
          bind: false,
          needCaptcha: true,
          username: `${this.formFields.areaCode}+${this.formFields.phone}`,
          verifyCode: this.formFields.captcha,
          ...geeTestData,
        });
      } catch (error) {
        // 默认错误提示
        // eslint-disable-next-line
        const { response } = error;
        const { data } = response;
        this.$message.error(data.message || data.msg);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../style.scss';

.login-form {
  padding: 0;

  & .el-form-item {
    margin-top: 32px;
  }
}

.submit-btn {
  width: 100%;
  margin-top: 20px;

  /deep/ .el-icon-loading {
    margin-left: -26px;
  }
}
</style>
