






import { Vue, Component, Prop } from 'vue-property-decorator';

export interface GeeTestParams {
    gt: string,
    challenge: string,
    success: boolean, // 表示用户后台检测极验服务器是否宕机
    // eslint-disable-next-line camelcase
    new_captcha: boolean, // 用于宕机时表示是新验证码的宕机
}

export type GetParamsFun = () => Promise<GeeTestParams>;

export type GeeTestProduct = 'float' | 'popup' | 'custom' | 'bind';

@Component
export default class extends Vue {
  @Prop({
    type: Object,
    default: null,
  })
  private params!: any;

  @Prop({
    type: Function,
    default: () => null,
  })
  private getParams!: GetParamsFun;

  @Prop({
    type: String,
    default: 'bind',
  })
  private product!: GeeTestProduct;

  private captchaObj = null

  private isReady = false

  private called = false // 是否在初始化前调用过verify

  private _isDestroyed = false;

  async mounted() {
    let params = this.params;
    if (this.getParams) {
      params = await this.getParams();
    }
    this.init(params);
  }

  beforeDestroy(): void {
    if (this.captchaObj) {
      (this.captchaObj as any).destroy();
    }
  }

  // methods: {
  init(data: GeeTestParams): void {
    if (this._isDestroyed) {
      return;
    }
    // eslint-disable-next-line
    initGeetest({
      gt: data.gt,
      challenge: data.challenge,
      offline: !data.success, // 表示用户后台检测极验服务器是否宕机
      new_captcha: data.new_captcha, // 用于宕机时表示是新验证码的宕机
      https: true,
      product: this.product,
    }, (captchaObj) => {
      if (this._isDestroyed) {
        (captchaObj as any).destroy();
        return;
      }
      this.captchaObj = captchaObj;
      this.handleInitSuccess();
    });
  }

  /**
     * 初始化极验成功
     * @param {*} captchaObj
     * @param {*} geeTestOptions
     */
  handleInitSuccess() {
    const { captchaObj } = this;
    const product = this.product;

    this.$emit('init:success', captchaObj);
    // 如果是 popup, float, custom 类型, 将验证按钮插到当前元素上
    if (product === 'popup' || product === 'float' || product === 'custom') {
      (captchaObj as any).appendTo(this.$el);
    }

    (captchaObj as any)
      .onReady(this.handleReady)
      .onSuccess(this.handleSuccess)
      .onError(this.handleError)
      .onClose(this.handleClose);
  }

  handleReady() {
    this.isReady = true;
    if (this.called) {
      (this.captchaObj as any).verify();
      this.called = false;
    }
    this.$emit('init-ready');
  }

  // 处理验证成功
  handleSuccess() {
    this.$emit(
      'varify-success',
      (this.captchaObj as any).getValidate(),
    );
  }

  // 验证出错
  handleError(e: Error) {
    // eslint-disable-next-line
    console.error(e);
    this.$emit('varify-error', e);
  }

  // 对于product为bind形式的验证。当用户关闭弹出来的验证时，会触发该回调。
  handleClose() {
    this.$emit('varify-close');
  }

  triggerVerify() {
    if (this.isReady) {
      (this.captchaObj as any).verify();
    } else {
      this.called = true;
    }
  }
  // },
}
