<!-- eslint-disable max-len -->
<template>
  <el-dialog
    :visible="visible"
    :show-close="false"
    width="468px"
    close-on-click-modal
    close-on-press-escape
    custom-class="agreement-dialog"
    @close="$emit('update:visible', false)"
  >
    <h1 class="title">
      创投Live社区指南
    </h1>

    <div class="content">
      <p>这份指南旨在帮助您更好的了解：</p>
      <p> 1：创投Live的沟通习惯</p>
      <p> 2：需要遵守的一些沟通准则</p>
      <h2>一、沟通习俗</h2>
      <p>创投Live 是一个创投交流社区，你可以在这里分享、学习、建立有意义的链接，以下是我们希望您能遵守的一些沟通习俗：</p>
      <p>1. 尊重每一人，耐心听完对方的讲述。</p>
      <p>2. 尽量去标签化的去理解、包容、接纳不同的人和观点。</p>
      <p>3. 培养有意义的关系 关注每一个你认为有价值的发言者。</p>
      <p>4. 在嘉宾分享过程中，欢迎随时举手，上台后请先关闭麦克风，等待主持人开放讨论。</p>
      <p>5. 创投Live 的魅力在于沟通，每次发言的时常不宜过长，创造一个沟通和讨论的氛围。</p>

      <h2>二、沟通原则</h2>
      <p>创投Live 的用户不得违反国家的法律法规。根据《互联网新闻信息服务管理规定》，用户的言论不得含有下列内容：</p>
      <p>1. 违反宪法确定的基本原则的；</p>
      <p>2. 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</p>
      <p>3. 损害国家荣誉和利益的；</p>
      <p>4. 煽动民族仇恨、民族歧视，破坏民族团结的；</p>
      <p>5. 破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
      <p>6. 散布谣言，扰乱社会秩序，破坏社会稳定的；</p>
      <p>7. 散布淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的；</p>
      <p>8. 侮辱或者诽谤他人，侵害他人合法权益的；</p>
      <p>9. 煽动非法集会、结社、游行、示威、聚众扰乱社会秩序的；</p>
      <p>10. 含有法律、行政法规禁止的其他内容的。</p>
      <p>维护一个气氛良好的直播间不是一件容易的事情，如果有任何用户违反沟通原则，我们会将该用户进行封号处理。所有的直播都将会按照国家相关法律法规要求进行完整录音保存。</p>
    </div>
    <div class="btn-wrapper">
      <el-button
        class="close-btn"
        type="primary"
        @click="$emit('update:visible', false)"
      >
        关闭
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import './agreement.scss';
</style>
