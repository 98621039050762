import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
// createPersistedState 需要持久化的state。
import login from './login';
import user from './user';
import topbar from './topbar';
import room from './room';
// import getters from './getters';

Vue.use(Vuex);
const store = new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
      key: `vuex_${__g_config__.storeVersion}`,
    }),
  ],
  modules: {
    login,
    user,
    topbar,
    room,
  },
  strict: process.env.NODE_ENV !== 'production',
});

export default store;
