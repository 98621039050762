export default {
  inserted(el, binding) {
    const { value, arg } = binding;
    switch (arg) {
    case 'click':
      el.addEventListener('click', () => {
        if (window.sensors) {
          sensors.quick('trackAllHeatMap', el, value);
        }
      }, false);
      break;
    default:
      // eslint-disable-next-line
        console.error('埋点类型错误');
    }
  },
  // unbind(el, binding) {
  //   // todo
  // },
};
