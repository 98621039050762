<!-- eslint-disable max-len -->
<template>
  <el-dialog
    :visible="visible"
    :show-close="false"
    close-on-click-modal
    close-on-press-escape
    custom-class="agreement-dialog"
    @close="$emit('update:visible', false)"
  >
    <h1 class="title">
      用户服务协议
    </h1>

    <div class="content">
      <p>
        欢迎您使用鲸准对接平台（下称“本平台”），您在使用本平台提供的服务之前，请仔细阅读《用户服务协议》《隐私政策》。本协议各条款标题仅为帮助您理解该条款表达的主旨之用，不影响或限制本协议条款的含义或解释。当您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后，即表示您已充分阅读、理解并接受本协议的全部内容。阅读本协议的过程中，如果您不同意本协议或其中任何条款约定，您应立即停止注册或登录程序。
      </p>

      <h2>第1条 定义与解释</h2>

      <p>除非本协议另有定义，下列词语具有以下含义：</p>

      <p>鲸准对接平台（“本平台”） 指为您提供融资项目信息发布、需求对接等相关服务的互联网平台。</p>

      <p>运营方 指北京鲸准数服信息科技有限责任公司或其关联/指定公司。</p>

      <p>您 指进入本平台注册或使用本平台服务的个人/企业用户，包括融资公司、投资人。</p>

      <p>融资公司 指在本平台发布的拟进行融资信息的公司或其他法律主体。</p>

      <p>投资人 指在本平台寻求投资项目的投资人。</p>

      <p>融资项目 指您通过在本平台发布信息并通过本平台寻求融资的非公开股权融资项目。</p>

      <h2>第2条 本平台的功能服务及相关说明</h2>

      <p>2.1 您有权依据本协议的约定使用本平台。</p>

      <p>2.2 本平台主要服务功能模块包括：</p>

      <p>(1) 对接服务：您可以通过该板块下的“找项目”、“找投资”服务尝试与项目方或投资人建立对接；</p>

      <p>(2) 查数据：您可以通过该功能查看行业热度值、投资机构的相关公开数据、投资事件及行业研究报告；</p>

      <p>(3) 创投Live：您可以通过该功能发起或参与分享会或路演语音图文在线直播，获得更多关注，具体包括发起直播、预约直播、观看直播、观看回放、直播间内发言互动及关注五项功能。</p>

      <p> 创投Live使用说明如下：</p>
      <p> ① 依据国家法律法规及监管要求，创投Live直播功能的实现需要您进行身份认证，否则您将无法使用该功能；</p>
      <p> ② 为实现产品功能的合法合规，故创投Live直播功能实行预约制及内容审核制，即所有直播均需提前将其直播内容提交本平台审核，包括上传直播时使用的演示文稿（PPT或PDF版）和Live海报，本平台审核通过后方可按事先预约的时间发起直播，无法发起实时直播；</p>
      <p> ③ 您可通过点击“发起Live”设定直播主题、开始时间、详情描述，并根据您身份认证情况及分享类型选择Live类型及房间类型，上传直播时使用的演示文稿及Live海报；</p>
      <p> ④ 您点击“发起Live”后，本平台将对您上传的演示文稿及Live海报进行内容合法合规的审核，审核结果会以本平台通知、短信等方式告知您，但本平台的审核不代表本平台对您所上传的演示文稿及Live海报内容向任何第三方承担任何法律责任，您必须确保提交审核的内容不侵犯任何第三方的合法权益；</p>
      <p> ⑤ 您可邀请嘉宾共同进行直播，本平台仅支持邀请互相关注的好友作为嘉宾；</p>
      <p> ⑥ 目前仅支持在鲸准APP发起创投Live，预约/观看/直播操作可在鲸准APP、鲸准微信端小程序、web端(网址为：https://live.capitalcoffee.club   )进行；</p>
      <p> ⑦ 您可预约观看或直接观看其他用户发起的允许围观的直播，并可申请上桌与主播进行互动，互动过程需进行自我身份介绍，且保证互动内容与直播主题相关，不发表任何涉嫌违法、违规、敏感政治话题、侵犯第三方隐私等内容等不良言论，若发表上述言论本平台将予以禁言处理；</p>
      <p> ⑧ 直播结束后依据您Live类型、房间类型及用户类型，与之匹配的本平台认证用户可以回看您的直播；</p>

      <p> 2.3 本平台将依据本协议的约定向您提供互联网在线服务，主要包括：</p>

      <p> (1) 运营维护本平台，供您发布、更新、完善融资项目等信息；</p>
      <p> (2) 为融资公司与投资人搭建交流平台，提供信息对接服务；</p>
      <p> (3) 通过本平台在线提供的创投Live等其他相关服务。</p>

      <p>2.4 运营方保留自行决定暂时或永久禁用或终止任何本平台应用程序和/或网站功能，和在任何时候删除任何内容的权利，而无需事先取得您或任何第三方同意。  </p>
      <p>2.5 本平台可能包含第三方链接，本平台不负责任何第三方链接网站的内容或操作。本平台提供这些链接仅为您使用之便利，不代表本平台与链接网站的运营方有任何关联。</p>
      <p>2.6 为使您正常使用本平台，当您使用上传、下载、分享以及直播功能时，本平台将提醒您进行相应权限授权，权限一般为：手机存储、通讯录、摄像头、麦克风常规功能，且该功能授权仅在您使用对应功能时开启，如您拒绝授权，可能导致部分功能无法正常使用，但不影响使用本平台。</p>

      <h2>第3条 行为规范</h2>

      <p>3.1 除非法律允许或经本平台事先书面同意，您不得从事下列行为：</p>

      <p>(1) 对本平台的任何部分进行修改、翻译、反向工程、反编译、反汇编或者以其他方式尝试发现软件的源代码；</p>

      <p>(2) 对本平台进行扫描、探查、测试，以检测、发现、查找其中可能存在的BUG或弱点；</p>

      <p>(3) 基于本平台或文件制作派生软件；</p>

      <p>(4) 删除本平台上的任何所有权声明或标签；</p>

      <p>(5) 其他未经本平台明示授权的行为。</p>

      <p>3.2 您同意遵守适用于本平台的所有法律法规和协议。本平台保留对涉嫌违反法律法规或协议的您采取任何适当的法律行动的权利， 包括但不限于向监管机构、执法人员或其他有关第三方披露任何涉嫌非法活动的您信息，您应独自承担由此而产生的一切法律责任。</p>

      <h2>第4条 本平台的承诺与保证</h2>

      <p>4.1 运营方承诺并保证其是依据中国法律设立并有效存续的有限责任公司，其具有一切必要的权利及能力订立并履行本协议。</p>

      <p>4.2 本平台承诺并保证按照本协议第2条的服务内容向融资公司及投资人提供非公开融资服务，并及时维护、管理、更新等服务。 但本平台不对融资成功、投资交易达成、交割、投资收益等事项做出任何性质的承诺、担保或保证。</p>

      <p>4.3 本平台承诺并保证对您提供的且存储于其服务器的信息等相关资料进行妥善保存。如该等资料因不可抗力或黑客攻击等原因造成损毁或丢失的，本平台将尽力防止相关损失扩大。</p>

      <p>4.4 本平台非常重视用户个人信息的保护，在您使用本平台提供的服务时，本平台依据《鲸准平台用户隐私政策》收集、存储、使用、对外提供和保护您的个人信息，详情请参阅《鲸准平台用户隐私政策》。</p>

      <h2>第5条 您的承诺与保证</h2>

      <p> 5.1 您承诺并保证具有一切必要的权利及能力订立及履行本协议。</p>

      <p>5.2 您承诺并保证将遵守本协议以及其与本平台订立的其他协议的约定，并遵守本平台制定并发布的其他相关制度、规则，维护本平台的正常运作及其信誉，不实施有损于本平台的行为。</p>

      <p>5.3 您承诺并保证提供或发布的所有信息（包括但不限于身份信息、融资项目信息等）是真实、合法、完整、及时、有效的，符合中国各项法律、法规、部门规章、规范性文件以及平台制定并发布的相关规则、制度的规定，不存在虚假、误导性陈述或遗漏，不存在侵犯任何第三方合法权益。您应对已发布的融资项目信息进行及时的更新、维护和管理。</p>

      <p>5.4 您承诺并保证配合本平台对您提交的融资项目信息的真实性、准确性、完整性、及时性、有效性及合法性进行审核（如需），并将及时提供平台要求的其他信息。当本平台有合理理由怀疑您提供的资料不实、非法、无效或不完整，本平台有权拒绝、暂停或终止向您提供本服务。该约定并不代表本平台及其运营方承担对融资项目信息进行真实性、准确性、完整性、及时性、有效性及合法性审核的义务与责任。</p>

      <p>5.5 您承诺并保证您提供或发布的所有信息（包括但不限于注册信息、融资项目信息等）不存在侵犯其他任何第三方知识产权及其他合法权利的情形并就违反本承诺的行为承担全部法律责任。</p>

      <p>5.6 您知悉并同意：为完善服务内容、改善服务体验，本平台有权对您进行的直播内容进行合理、必要剪辑（包括不限于删除敏感违规部分、剪辑精彩片段、剪切与直播主题无关的内容等），有权根据业务发展需要对本平台系统进行升级、改造，以及对本平台服务功能、内容及方式等进行调整。</p>

      <h2>第6条 特别承诺事项</h2>

      <p>6.1 您承诺并保证其所提交、创建的融资项目真实、合法，承诺不存在下列行为，包括但不限于：（1）违反国家限制或禁止经营的相关法律法规； （2）侵犯国家、集体、第三人的合法权利；（3）违反公序良俗。</p>

      <p>6.2 融资公司及投资人承诺并保证在通过本平台或线下场合进行磋商、谈判、交易过程中，由其自行就交易方式、融资金额、融资条件等事项进行沟通、洽商并另行签署相关交易文件，本平台及运营方不对此承担任何责任和义务，融资公司承诺并保证不向投资人承诺投资款不受损失或者承诺最低收益或做出任何其他类似的承诺与保证。</p>

      <p>6.3 您承诺并保证不通过本平台或运营方其他在线平台采用公开或变相公开方式发行证券、募集资金，不向不特定对象发行证券、募集资金。</p>

      <h2>第7条 免责声明</h2>

      <p>7.1 本平台的信息来源于公开渠道、您主动披露以及根据公开渠道或您披露获取的信息推算的结果，不作为提供法律、会计、投资或其他类型的建议的支持。您充分了解并同意对本平台提供的一切内容加以核实和判断，并承担因使用本平台而引起的该等风险，包括因对本平台提供的信息的正确性、完整性、及时性的依赖而产生的风险，本平台无法且不会对非因本平台过错导致的因前述风险而招致的任何损失或损害承担责任。</p>

      <p>7.2 本平台提供的一切内容仅供您参考，不构成也不应被解释为对任何投资或交易的招揽，或是以其他方式提供财务、税收、法律或其他类型服务的建议。如果您在寻求某一方面的建议而并非信息，应咨询专业顾问的意见。</p>

      <p>7.3 除因本平台过错，本平台不对因不可抗力导致在使用本平台过程中遭受的损失承担责任。该等不可抗力事件包括但不限于国家法律、法规、政策及国家机关的命令及其他政府行为或者其它的诸如地震、水灾、雪灾、火灾、海啸、台风、罢工、战争等不可预测、不可避免且不可克服的事件。</p>

      <p>7.4 第三方在本平台中投放的广告、链接或者其它形式的推广内容，均是由其自行提供的，您应当自行判断其真实性，本平台对其推广内容不作任何明示或者默示的担保。</p>

      <p>7.5 任何因您的原因导致第三方对本合同履行引起之争议、诉求、纠纷均与本平台无关，如给本平台造成损失的，您应就本平台因此遭受的实际损失给予全额赔偿。</p>

      <p>7.6 您使用本平台的人员进行的涉及您的信息与数据安全的重要操作，包括但不限于数据导出、管理员密码重置、特殊数据查询、删除数据等所产生的结果由您负责。</p>

      <h2>第8条 协议的变更和生效</h2>

      <p>8.1 本平台有权根据法律法规政策、系统升级、业务发展需要等合理原因变更本协议条款，并在相关页面进行通知。您可以在本平台相关页面查阅最新版本的协议条款。</p>

      <p>8.2 本协议条款变更后，如果您登录、同意变更后的条款继续并使用本平台，即代表您已接受变更后的协议。如果您不接受变更后的协议，应当停止使用本平台。</p>

      <h2>第9条 法律适用和争议解决</h2>

      <p>9.1 本协议的订立、效力、解释、履行和争议的解决应受中国法律法规的管辖，并依其解释。</p>

      <p>9.2 在本协议执行过程中，若出现与本协议有关的争议，各方应通过友好协商解决；若任何争议无法在争议发生后十五天内通过协商解决，则任何一方有权向本平台所在地人民法院提起诉讼。</p>

      <h2>第10条 其他</h2>

      <p>10.1 各方同意，运营方有权在向其他方发出书面通知后，将运营方在本协议下的全部或部分权利义务转让给运营方的关联方。为免歧义，关联方是指直接或间接控制运营方，或被运营方控制，或与运营方共同被控制的任何其他实体。</p>

      <p>10.2 本协议部分条款无效、被撤销或者终止的，不影响其他条款的效力，其他条款仍然有效。</p>

      <p>10.3 如果您对本协议有任何疑问、意见或建议，可通过在线客服或以下邮箱与我们联系：service@jingdata.com。</p>
    </div>
    <div class="btn-wrapper">
      <el-button
        class="close-btn"
        type="primary"
        @click="$emit('update:visible', false)"
      >
        关闭
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../agreement.scss';
</style>
